@use "../base";

.c-table-old {
  background-color: base.$colorVanilla;
  padding: base.$spacingLarge;
  overflow-x: auto;

  table {
    table-layout: fixed;
  }

  .c-table--row {
    border-top: 2px solid black;
    border-collapse: collapse;
  }
  tr:first-child {
    border-top: none;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: base.$spacing 0;
    padding-right: base.$spacing;
    text-align: left;
  }
  td {
    vertical-align: top;
  }
  h3 {
    margin: base.$spacing 0;
  }
}
.c-table-heading {
  padding: base.$spacing 0;
}

.c-table {
  scrollbar-color: base.$colorDarkClouds;
  table {
    // table-layout: fixed; // commented because fixed will break responsive table scroll
    white-space: pre-wrap;
    background-color: base.$colorGreySky;
    width: 100%;
    border-collapse: collapse;
  }

  .c-table__row--body {
    border: 7px solid base.$colorGreySky;
  }

  .c-table__row--body:first-child td {
    border-top: 7px solid base.$colorGreySky;
  }

  .c-table__row--heading {
    border: 7px solid base.$color9000Tertiary;
    background-color: base.$color9000Tertiary;

    p {
      font-weight: 500;
    }
  }

  td,
  th {
    padding-left: base.$spacingSmall;
    padding-right: base.$spacingSmall;
    text-align: left;
  }

  td {
    background-color: base.$colorWhite;

    padding-top: base.$spacingSmall;
    padding-bottom: base.$spacingSmall;

    vertical-align: bottom;
  }

  // Reduce cell height by removing margin around top and bottom paragraphs
  .c-portableText .o-wrapper--narrow:first-child > p {
    margin-top: 0;
  }
  .c-portableText .o-wrapper--narrow:last-child > p {
    margin-bottom: 0;
  }

  h3 {
    margin: 0;
    color: base.$colorSoftGreen;
  }
}

.c-renblad-table {
  overflow-x: auto;
  margin-right: calc(-1 * var(--renblad-content-margin-right));

  margin-top: base.$spacingLarge;
  margin-bottom: base.$spacingLarge;
  flex: 1;

  th,
  td {
    p:first-of-type {
      padding-top: 0;
      margin-top: 0;
    }
    p:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    vertical-align: top;
  }

  table {
    background-color: var(--tertiary-color);
    border-collapse: unset;
    border-spacing: 0 base.$spacingTiny;
    border-color: unset;
    padding: base.$spacingSmall;
    width: 100%;
  }

  caption,
  td,
  th,
  td {
    padding: base.$spacingSmall base.$spacing;
    line-height: 1.7;
  }

  caption,
  th {
    border: none;
    p {
      font-weight: 500;
    }
  }

  caption {
    background-color: var(--tertiary-shade);
    text-align: left;

    .copyable-link {
      float: right;
    }
  }

  tr {
    th,
    .c-table__row--heading {
      text-align: left;
      background-color: var(--tertiary-color);
    }

    td,
    .c-table__row--body {
      background-color: base.$colorWhite;
      border-top: 1px solid var(--secondary-color);
      border-bottom: 1px solid var(--secondary-color);
      border-left: none;
      border-right: none;
    }

    td:first-of-type {
      border-left: 1px solid var(--secondary-color);
    }

    td:last-of-type {
      border-right: 1px solid var(--secondary-color);
    }
  }

  .cell-center {
    text-align: center;
  }

  .cell-left {
    text-align: left;
  }

  .cell-right {
    text-align: right;
  }

  .align-bottom {
    vertical-align: bottom;
  }
  .align-middle {
    vertical-align: middle;
  }
  .align-top {
    vertical-align: top;
  }
  .align-baseline {
    vertical-align: baseline;
  }

  .rowDelete,
  .colDelete {
    color: rgb(242, 242, 242);
    padding: 0;
    text-align: center;
  }

  .rowDelete {
    padding-left: 0.5em;
  }

  .colDelete {
    padding-top: 0.5em;
  }

  .rowDelete > button,
  .colDelete > button {
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    width: 1em;
    height: 3px;
    line-height: 1em;
    background: rgb(242, 242, 242);
    align-items: center;
    justify-content: center;
  }

  .rowDelete > button:hover,
  .colDelete > button:hover {
    background: #ff0000;
  }

  table {
    overflow-x: scroll;

    tbody:has(th:only-of-type) {
      tr {
        th:first-child {
          position: sticky;
          left: 0;
        }
        th:only-child {
          position: static;
        }
      }
    }
  }
}

// Inside Slate editor:
.next {
  .inside-slate {
    th,
    td {
      border-right: 1px solid base.$colorBlack;
    }
    th:last-of-type {
      border-right: unset;
    }
    td:last-of-type {
      border-right: 1px solid var(--secondary-color);
    }
  }
}
