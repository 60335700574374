@use "../base";

.c-expand-button__arrow {
  display: inline-block;
  text-align: right;
}

.c-expand-button__arrow button {
  color: base.$colorBrightGreen;
}
