@use "../base";

.c-search-bar {
}

.c-search-bar__search-field {
  display: flex;
  background-color: base.$colorGreySky;
  border: 1px solid base.$colorDarkClouds;

  input::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.c-search-bar__search-field div {
  flex-grow: 1;
  display: flex;
}

.c-search-bar__search-field input {
  flex-grow: 1;
  height: 4em;
  padding: 0 base.$spacing;
  font-size: inherit;
  border: none;
  background-color: transparent;
}

.c-search-bar__search-field__htmlrenblad {
  display: flex;
  background-color: base.$colorWhite;
  border-radius: 4px;
  input::-webkit-calendar-picker-indicator {
    display: none;
  }
  @media screen and (max-width: 59.99em) {
    margin: base.$spacingSmall;
    background-color: base.$colorGreySky;
  }
}

.c-search-bar__search-field__htmlrenblad input {
  flex-grow: 1;
  padding: base.$spacingSmall;
  font-size: inherit;
  border: none;
  color: inherit;
  background-color: inherit;
  @media screen and (max-width: 59.99em) {
    padding: base.$spacing;
  }
}

.c-search-bar__button {
  padding: 0 base.$spacing;
  border-radius: 4px;
  color: base.$colorPineGreen;
  font-size: base.$font;
}

.c-search-bar__button:hover {
  color: base.$colorBrightGreen;
}

.c-search-bar__button svg {
  vertical-align: middle;
  margin-right: base.$spacingTiny;
}

@media (max-width: 59.99em) {
  .c-search-bar__button {
    padding: 0 0.5rem;
  }

  .c-search-bar__label {
    display: none;
  }
}

.c-search-bar--label {
  font-size: base.$font;
}

.c-search-bar--top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -30px;
}

.c-search-bar__suggestions--wrapper {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  max-width: base.$siteWidth;
  width: 100%;
  left: 0;
  right: 0;
}

.c-search-bar__suggestions {
  background-color: white;
  padding: base.$spacing;
  border: 1px solid black;
  z-index: base.z-index(content);
  height: fit-content;
  margin: 0 1.1rem;

  li {
    cursor: pointer;
  }
  li:hover {
    text-decoration: underline;
    color: base.$colorBrightGreen;
  }
}
