@use "../base";

.c-simple-link {
  color: base.$colorBlack;
  padding: base.$spacingSmall 0;
  display: flex;
  align-items: center;
  transition: 0.15s;
  margin-bottom: base.$spacingTiny;
}
.c-simple-link:hover {
  border-radius: base.$spacingSmall;
  background-color: base.$colorWhite;
  padding: base.$spacingSmall base.$spacing;
  margin: 0 base.$spacingNegative base.$spacingTiny;
}

.c-simple-link__title {
  margin: 0;
  width: 68%;
  font-weight: 500;
}

.c-simple-link__category {
  width: 22%;
  margin: 0;
}

.c-simple-link__arrow {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  color: base.$colorPineGreen;
}

@media (max-width: 60em) {
  .c-simple-link {
    flex-direction: column;
    align-items: flex-start;
    padding: base.$spacing base.$spacingTiny;
    border-bottom: 1px base.$colorDarkClouds solid;
  }

  .c-simple-link__title {
    width: 100%;
    font-weight: 500;
  }

  .c-simple-link__category {
    width: 100%;
  }

  .c-simple-link__arrow {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: base.$spacingNegative;
    margin-left: base.$spacingNegative;
  }
}
