@use "sass:color";
@use "../base";

.renblad-menu-container {
  z-index: base.z-index(navigation);
}

@media screen and (max-width: base.$renblad-tablet) {
  .dev-tools-toggle {
    display: none;
  }
}

.renblad-mobile-table-of-contents {
  display: none;
  @include base.no-aside {
    display: flex;
  }
  & {
    background: var(--white-color);

    flex-direction: column;

    padding: var(--space-xx-small) var(--space-x-small);

    overflow-x: scroll;
    max-height: 90vh;

    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);
  }

  &.hide {
    display: none;
  }

  button {
    display: flex;
    align-items: center;
    gap: var(--space-xx-small);
    margin: 0 auto;
    margin-top: 0px;
    color: var(--primary-color);
    text-decoration: underline;
    text-underline-offset: 4px;

    &.toggled {
      svg {
        position: relative;
        transform: rotate(180deg);
        top: 4px;
      }
    }
  }
  a {
    background: inherit;
    color: var(--primary-color);
    text-decoration: underline;
    text-underline-offset: 4px;
    margin-bottom: var(--space-xx-small);

    &:first-of-type {
      margin-top: var(--space-x-small);
    }
  }
}

.renblad-menu {
  display: flex;
  gap: 0.5rem;
  max-width: var(--renblad-max-width);
  margin: 0 auto;
  //Horizontal bar indicating edit mode is active
  &.editmode-enabled {
    box-shadow: 0 var(--space-x-small) var(--pine-green-color);
  }
  .identity,
  .options {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .identity {
    flex-basis: calc(
      var(--renblad-aside-width) + var(--renblad-aside-horizontal-padding)
    );
    flex-grow: 0;
    padding-left: var(--renblad-aside-horizontal-padding);
    margin-right: var(--renblad-aside-horizontal-padding);
  }
  .options {
    gap: var(--space-medium);
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;

    @media screen and (max-width: base.$renblad-tablet) {
      gap: revert;
    }

    .favorite-button {
      display: flex;
      align-items: center;
      flex-grow: 0;
      svg {
        margin: 0 var(--space-xxx-small);
        color: var(--primary-color);
        height: var(--heading-medium-font-size);
        width: var(--heading-medium-font-size);
      }

      @media screen and (max-width: base.$renblad-font-breakpoint) {
        display: none;
      }
    }
    .c-more-dropdown {
      display: flex;
      align-items: center;
      flex-grow: 0;
      padding-right: var(--space-x-small);
      button {
        display: flex;
        align-items: center;
        font-size: var(--button-font-size);
        svg {
          margin: 0 var(--space-xxx-small);
          color: var(--primary-color);
          height: var(--heading-medium-font-size);
          width: var(--heading-medium-font-size);
        }
      }
    }
    .placeholder-search-field {
      flex-grow: 1;
      padding: var(--space-xx-small);

      .placeholder-mock-input {
        background-color: var(--grey-sky-color);
        padding: 0 var(--space-x-small);
        border-radius: 0.3rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          color: var(--dark-clouds-color);
        }

        svg {
          color: var(--pine-green-color);
          height: var(--heading-large-font-size);
          width: var(--heading-large-font-size);
        }
      }

      @media screen and (max-width: base.$renblad-no-right-margin) {
        display: none;
      }
    }
    .edit-placeholder {
      flex-basis: var(--space-xxx-large);
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

.renblad-menu__button-text {
  font-size: var(--button-font-size);
  font-weight: var(--font-weight);

  border-bottom: 1px solid base.$colorBlack;
  &:hover {
    border-color: color.adjust($color: base.$colorBlack, $alpha: -1);
  }
}

.renblad-menu__group {
  display: flex;
  justify-content: space-between;
}

.renblad-menu__align-center {
  align-self: center;
}
.renblad-menu__grow {
  flex-grow: 2;
}
.renblad-menu__edit-status {
  display: flex;
  font-size: base.$fontSmall;
  white-space: nowrap;

  @media screen and (max-width: base.$renblad-font-breakpoint) {
    label,
    .open-edit-mode {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      z-index: base.z-index(top);
    }
  }

  @media screen and (max-width: base.$renblad-small-mobile) {
    display: none;
  }
}
.renblad-menu__toggle-switch-thumb {
  color: base.$colorWhite;
}

//Redigeringsmodus-button
button.editmode-enabled {
  color: white;
  font-size: base.$fontSmall;
  border-radius: 0%;
  background: var(--pine-green-color);
  border-color: var(--pine-green-color);
  .renblad-menu__toggle-switch-thumb {
    background: #1be066;
  }

  span.MuiSwitch-root:nth-child(1) > span:nth-child(2) {
    background: #ffffff;
  }
  //Don´t change on hover
  &:hover {
    color: white;
    background-color: var(--pine-green-color);
  }
}

button.editmode-disabled {
  color: white;
  font-size: base.$fontSmall;
  border-radius: 0%;
  border: var(--primary-color);
  background: var(--primary-color);
  display: flex;
  align-items: center;
  gap: var(--space-xxx-small);
  .renblad-menu__toggle-switch-thumb {
    background: #dcb5a7;
  }
  .MuiSwitch-track {
    background: #f0f0f0;
  }
  //Don´t change on hover
  &:hover {
    color: white;
    background-color: var(--secondary-color);
  }
  svg {
    height: var(--space-small);
    width: var(--space-small);
  }
}

.renblad-menu__actions {
  display: flex;
  flex-grow: 0.2;
  justify-content: space-between;
  align-items: stretch;
}
.renblad-menu__actions-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: base.$colorBlack;

  * {
    display: block;
  }
}
.renblad-menu__actions-button-text {
  color: var(--black-color);
  font-size: base.$fontSmall;
  font-weight: 400;

  border-bottom: 1px solid base.$colorBlack;
  margin: 0 5px;

  /* make border transparent instead of removing it to avoid shifting the text  */
  .renblad-menu__actions-button:hover & {
    border-color: color.adjust($color: base.$colorBlack, $alpha: -1);
  }
}

.c-more-open__wrapper {
  position: relative;
  z-index: base.z-index(above-top);
  right: 120px;
  top: 41px;
}
.c-more-open {
  border: 1px solid base.$colorPineGreen;
  background-color: base.$colorWhite;
  width: var(--space-xxx-large);
  padding: 0em 1em;
  position: absolute;
  top: -0.5rem;
}

.c-more-open__list {
  margin: 0;
  padding: 0;
}
.c-more-open__list li {
  margin-top: 1em;
  margin-bottom: 1em;
}

.renblad-menu__series-number {
  height: 3.75rem;
  display: flex;
  padding: 0 var(--renblad-menu-seriesnumber-padding);
  font-size: var(--heading-large-font-size);
  font-weight: 900;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: base.$colorWhite;
}

// Logo //
.renblad-menu__logo {
  height: 3.75rem;
  display: flex;
  align-items: center;
  color: var(--primary-color);

  a {
    text-decoration: none;
  }

  svg {
    width: auto;
    height: 1.5rem;
    fill: var(--primary-color);
  }

  .logo {
    padding-right: var(--space-x-small);
    line-height: 0.5rem; // avoid extra span height
  }

  .mobile-only {
    display: none;
  }

  @media screen and (max-width: base.$renblad-tablet) {
    .logo {
      display: none;
    }
    .mobile-only {
      display: block;
    }
  }
}

// Button for opening dropdown menu //
.renblad-menu__button {
  font-size: 1em;
  color: var(--primary-color);
  font-weight: 400;
}
.renblad-menu__button:hover {
  color: var(--tertiary-shade);
}
