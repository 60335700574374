@use "../base";

.c-document-menu {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0px var(--space-200);
  gap: var(--space-200);
  height: var(--space-300);
  flex-wrap: wrap;

  @include base.no-aside {
    padding: 0px var(--space-050);
    gap: var(--space-050);
    height: auto;
    min-height: var(--space-300);
    margin-left: var(--space-050);
  }

  a {
    padding: var(--space-100) 0px;
    color: var(--font-color);

    &:hover,
    &.active {
      border-bottom: 4px solid var(--primary-color);
      padding-bottom: calc(var(--space-100) - 4px);
    }
  }
}
.c-document-top.in-content {
  border-bottom: var(--dimension-border-default) solid
    var(--color-border-default);
  margin-bottom: var(--space-150);
  background-color: var(--color-background-default);
  padding-top: var(--space-0125);

  .c-document-top-wrapper {
    max-width: none;
    width: 100%;
    margin: var(--space-100) 0;
  }
}

.c-document-menu a.c-button-secondary-default-small {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-default);
  border: var(--dimension-border-default) solid
    var(--button-color-border-secondary-default);
  background: var(--button-color-background-secondary-default);
  padding: 4px 14px;
  color: var(--button-color-text-secondary-default);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  line-height: 150%;
  letter-spacing: 0.32px;

  &:hover {
    background: var(--button-color-background-secondary-hover);
    border: var(--dimension-border-default) solid
      var(--button-color-border-secondary-hover);
    color: var(--button-color-text-secondary-hover);
  }
}
