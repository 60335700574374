@use "../../base";

.toc-header {
  display: flex;
  width: inherit;
  cursor: default;
  padding: var(--space-100, 16px) var(--space-150, 24px);
  margin-bottom: var(--space-100);
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  background: var(--color-brand-background-primary-default, #E1FBDF);
}

.back-link {
  display: flex;
  align-items: center;
  gap: var(--space-050);
  justify-content: space-between;
  font-family: var(--font-family-sans, "Roboto Flex");
  font-size: var(--font-size-sm, 16px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: 150%; /* 24px */
  color: var(--color-interactive-text-default);
  margin-bottom: 0.5rem;
}

.top-level-header {
  align-self: stretch;
  color: #000;
  /* heading/2 */
  font-family: var(--font-family-sans, "Roboto Flex");
  font-size: var(--font-size-xl, 30px);
  font-style: normal;
  font-weight: var(--font-weight-bold, 600);
  line-height: 140%; /* 42px */
}

.top-level-card-text {
  align-self: stretch;
  margin-top: 0.5rem;
  color: var(--color-text-secondary, #395A55);
  /* heading/4 */
  font-family: var(--font-family-sans, "Roboto Flex");
  font-size: var(--font-size-md, 19px);
  font-style: normal;
  // font-weight: var(--font-weight-bold, 400);
  line-height: 140%; /* 26.6px */
}

.toc {
  cursor: default;
  overflow: auto;
  a {
    text-decoration: none;
    color: var(--color-text-primary, #0A2D29);
    border: 0;
    padding: 0;
    margin: 0;
  }
}

/* Top-level <li> only (loose docs) */
.toc-content > ul > li {
  font-family: var(--font-family-sans);
  cursor: default;
  padding: 0;
  margin: 0;
  padding: var(--space-050) 0 var(--space-050) var(--space-050);
}

.toc-content {
  max-height: 50vh;
  margin-top: var(--space-100);
  padding: var(--space-100);
  /* Nested <li> only */
  li {
    padding: var(--space-050);
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  > ul {
    // Gives a bit more space to see the last items in TOC when scrolling down.
    padding-bottom: 5em;
  }
  li > ul {
    margin: var(--space-050) var(--space-050) 0 var(--space-050);
    border-left: solid 1px var(--color-border-default);
  }
}

.nav-list {
  a {
    margin: 0;
    padding-left: 16px;
  }
}

.nav-list.unexpanded {
  overflow: hidden;
  display: none;
}

.nav-list.expanded {
  display: block;
}

.expandable svg {
  transition: transform 0.2s ease, color 0.2s ease;
  color: #74978F;
}

.expandable.expanded svg {
  transform: rotate(180deg);
  color: #395A55;
}

// List items with links i.e. navigable docs
li:has(> div:first-child > .isDoc:first-child):hover {
    border-left: 2px solid var(--color-border-hover, #74978F);
}

li:has(> div:first-child > .isDoc:first-child) {
    border-left: 2px solid transparent;
}

li.active:has(> div:first-child > .isDoc:first-child) {
  color: var(--color-brand-text-primary, #015E4C);
  font-weight: 400;
  border-left: 2px solid var(--color-feedback-border-positive, #02A15F);
  a {
    color: var(--color-brand-text-primary, #015E4C);
  }
}

span.active {
  font-weight: 700;
}

.expandable-container {
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandable {
  margin-right: 2em;
  width: 2rem;
  text-align: right;
  background: none;
  border: none;
  cursor: pointer;
}

.expandable[data-level="2"] {
  margin-right: 0.8em;
}

// * Mobile TOC * //
.brukerguide-mobile-menu {
    .toc {
        position: absolute;
        right: 20%;
        max-height: 70vh;
        max-width: 70vw;
        border: 1px solid var(--color-border-default);
        background-color: white;
        border-radius: 5px;
        padding-bottom: var(--space-300);
        * {
            max-width: 70vw;
        }
        .toc-header {
          max-height: 20vh;
          .back-link {
            font-size: var(--font-size-xs);
          }
        }
        .top-level-card-text {
          display: none;
        }
        .toc-content {
            padding: var(--space-050);
            max-height: 70vh;
        }
        .expandable-container {
            gap: 1em;
            font-size: var(--font-size-xs);
        }
        .expandable {
            transform: rotate(180deg);
            display: block;
        }
        .expandable.expanded svg {
            transform: rotate(360deg);
            color: #395A55;
        }
    }
}

// * Pagination * //
.pagination-container {
  display: flex;
  justify-content: space-between;
  max-width: var(--renblad-content-width);
  margin-top: 48px;
  a {
    text-decoration: none;
    color: var(--color-text-secondary);
  }
  a:hover {
    text-decoration: underline;
  }
  .prev-next-link {
    max-width: 50%;
    gap: var(--space-050);
    display: flex;
    p {
      margin: 0;
      font-size: var(--font-size-body-2);
    }
  }
  svg {
    position: relative;
    top: 2px;
    height: 24px;
    width: 24px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
