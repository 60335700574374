@use "../base";

.renblad-theme-debugger {
  display: flex;
  gap: base.$spacingSmall;
  flex-flow: row wrap;
  align-items: center;
  padding: 0 base.$spacing;
  background-color: base.$colorDarkClouds;
  color: base.$colorAir;
  &__setting {
    label {
      font-weight: 300;
    }
    select {
      margin: 0 base.$spacingSmall;
      background-color: base.$colorDarkClouds;
      color: base.$colorAir;
      border: 1px solid base.$colorAir;
      line-height: 1.7;
      font-size: 1rem;
      font-weight: 300;
    }
  }
}
