@use "../base";

.copyable-link {
  display: none;
}

.print-toc {
  page-break-after: always;
  a {
    color: inherit;
  }

  ul {
    margin-left: 0;
  }

  ul li ul {
    margin-left: 24px;
  }
  &__list {
  }
  &__list-item {
    a::after {
      content: leader(dotted) target-counter(attr(href url), page);
    }
  }
}

#front-page {
  page-break-after: always;
  display: flex;
  flex-direction: column;
  height: 297mm; // A4, https://docraptor.com/documentation/article/1067959-size-dimensions-orientation

  .print-banner {
    flex: 3rem 1 0;
    background-color: var(--tertiary-color);
    width: 100%;
    display: flex;
    align-items: stretch;

    .print-logo,
    .empty-space {
      flex: 160px 1 0;
    }
    .print-number {
      flex: 156px 0 0;
      width: 100%;
      span {
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }

    .print-logo {
      color: var(--primary-color);
      padding-left: 20mm;
    }

    .empty-space {
      padding-right: 20mm;
    }

    .print-logo,
    .print-number,
    .empty-space {
      padding-bottom: base.$spacing;
    }

    .print-logo {
      display: flex;
      align-items: flex-end;

      svg {
        width: 152px;
        margin-bottom: 4px;
      }
    }
    .print-number {
      display: flex;
      align-items: flex-end;
      font-size: 30px;
      font-weight: 700;
      line-height: 30px;
      color: base.$colorWhite;
      background-color: var(--primary-color);
      span {
        padding: 0 base.$spacingLarge;
      }
    }
  }

  .print-meta {
    flex: auto 0 0;
    margin: 0 20mm;

    h1 {
      size: 24px;
      line-height: 32px;
      margin-bottom: base.$spacing;
      margin: 32px 0;
    }
    .c-renblad-purpose {
      h2 {
        font-size: 1rem;
        font-weight: 700;
      }
      padding: 32px;
      margin-bottom: 32px;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  .print-download-notice {
    margin-top: 2rem;
    p {
      font-size: 1rem;

      a {
        color: var(--primary-color);
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }

  .print-logo-bottom {
    margin-top: 40px;
    margin-bottom: 20mm;
    svg {
      color: base.$colorBrightGreen;
    }
  }
}

#watermark {
  flow: static(watermarkflow);
  font-size: 100px;
  font-weight: 500;
  opacity: 0.2;
  transform: rotate(-55deg);
  text-align: center;
}

#top-left {
  position: running(headerLeft);
  display: flex;
  align-items: center;
  gap: 10mm;
  svg {
    text-align: center;
    color: var(--primary-color);
  }
  span {
    margin-left: 13mm;
  }
}

#bottom-left {
  font-weight: 300;
  font-size: 10pt;
  position: running(bottomLeft);
}

.c-renblad__main {
  padding: 0;
}

.c-renblad {
  max-width: revert;
  margin-bottom: 0;
}

.c-renblad-content {
  break-inside: auto;
  p,
  li {
    break-inside: avoid;
  }
}

.c-link-button,
.c-richText-nb-textblock,
.c-richText-formula,
.c-renblad-image,
.c-info-yellow {
  break-inside: avoid;
}

.c-decription-list {
  break-before: avoid;
}
.c-description-pair,
.c-description-pair dd,
.c-description-pair dt {
  break-inside: avoid;
}

.c-slate-table table {
  tr {
    break-inside: avoid;
  }
}

.c-portableText--renblad > .c-renblad-content {
  margin-right: 0;
}

@page {
  size: A4;
  margin: 20mm 20mm;
  font-size: 12pt;

  @prince-overlay {
    content: flow(watermarkflow);
  }

  @bottom-right {
    font-weight: 300;
    font-size: 10pt;
    content: "Side " counter(page, decimal) " av " counter(pages, decimal);
  }

  @bottom-left {
    font-weight: 300;
    font-size: 10pt;
    content: element(bottomLeft);
  }

  @top-left {
    font-weight: 300;
    margin-left: -20mm;
    content: element(headerLeft);
  }
}

@page :first {
  margin: 0;
}

.c-link-list {
  &__link {
    color: base.$colorBlack;

    &:visited {
      color: base.$colorBlack;
    }
  }
}

// use the full width of the paper
.c-renblad__main {
  max-width: 100%;
}

button,
.c-footer,
.c-scroll-to-top,
.c-topbar,
.c-search-bar,
.c-toc-accordion,
.c-renblad-part-menu {
  display: none;
}

.renblad-menu-container {
  position: static;
}

.renblad-menu__group {
  justify-content: start;
}

.renblad-menu__logo {
  padding: 0 !important;
}

// hides anchor tags
.block-utils__menu,
[data-testid="LinkIcon"] {
  display: none !important;
}

/*
  TODO: Move global print changes to component stylesheet,
   including displaying URL/href destination
  TODO: Do something richer for RENblads, append destination
   add data from dropdown/preview
 */
.ren-link {
  color: base.$colorBlack;
  text-decoration: underline;
}

//hide the theme debugger in test
.renblad-theme-debugger {
  display: none;
}

//hide the action-buttons, download, favourite etc
.renblad-menu__actions {
  display: none;
}

.c-renblad {
  //hide the hearing-banner
  .c-info-yellow {
    display: none;
  }

  // Prettier images
  .c-renblad-content-aside {
    border: none;

    // hide image dimension box
    .c-renblad-image__dimensions {
      display: none;
    }

    // hide "vis større bilde"-button
    .c-renblad-image__button-container {
      display: none;
    }
  }

  // start the link list on a new page
  .c-print-list {
    page-break-before: always;
  }
}

// todo this would be optimal, but this does not work:
// start the main content on a new page
// nav.c-toc div.c-toc__content.js-toc {
//   page-break-after: always;
// }

.c-print-user-info {
  margin: base.$spacing 0;
  padding: base.$spacing;
  border: 1px solid base.$colorBlack;
  display: flex;
  flex-direction: column;

  &__text {
    line-height: 0.5cm;
  }
  &__date {
    text-align: right;
  }

  &__flex-between {
    display: flex;
    justify-content: space-between;
  }
}
