@use "../base";

.c-todo {
  margin: var(--space-medium) 0;

  &__label {
    padding: var(--space-xx-small);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: base.$spacing;

    border: 1px solid black;

    font-weight: var(--font-bold-weight);

    svg {
      height: var(--space-small);
      width: var(--space-small);
    }

    h4 {
      display: flex;
      align-items: center;
      margin: var(--space-xx-small) 0;
      svg {
        margin-right: var(--space-xx-small);
      }
    }
  }

  &__content {
    padding: base.$spacing;
    margin-bottom: 0;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;

    .metadata {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      font-size: var(--label-font-size);
      font-weight: var(--font-bold-weight);
    }
    .c-block-content {
      p {
        margin-bottom: 0;
      }
    }
  }

  &.blocking {
    background-color: var(--pink-skin-color);
  }
  &.notBlocking {
    background-color: var(--vanilla-color);
  }

  &__arbeidsversjon {
    border: 1px solid black;
    margin-bottom: var(--space-medium);
    background-color: var(--white-color);

    &__label {
      display: flex;
      align-items: center;

      border-bottom: 1px solid black;
      padding: var(--space-x-small);

      font-weight: var(--font-bold-weight);

      svg {
        height: var(--space-small);
        width: var(--space-small);
      }

      h4 {
        display: flex;
        align-items: center;
        margin: var(--space-xx-small) 0;
        svg {
          margin-right: var(--space-x-small);
        }
      }
    }

    &__content {
      padding-inline: var(--space-xx-small);
      padding-block: var(--space-small);

      .task {
        padding: 0 var(--space-x-small);
        margin: var(--space-x-small);
        min-height: var(--space-xxx-large);

        border: 1px solid;
      }
      .assignee {
        padding: 0 var(--space-x-small);
        p {
          padding: var(--space-x-small);
          border: 1px solid black;
        }
        input {
          margin: 0 var(--space-x-small);
        }
      }
      .isBlockingTodo {
        padding: 0 var(--space-x-small);
      }
    }
  }
}
