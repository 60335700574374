/* Styles */

@use "tocbot-core";
@use "tocbot-default-theme";

// remove the animation for table of content because
// ToC is set to refresh every 2 seconds, running the transition every time
.is-collapsible {
  transition: none;
}
