@use "tocbot/tocbot";
@use "../base";

.c-toc-mobile-content {
  font-size: 14px;
  font-weight: 400;
  ol {
    list-style: none;
  }
}

.c-toc {
  padding: base.$spacing 0;

  &__title {
    margin-bottom: base.$spacingSmall;
    font-weight: 500;
    font-size: 14px;
  }
  &__content {
    @extend .c-toc-mobile-content;

    overflow-y: auto;
    max-height: 85vh;
    position: relative;

    .toc-list-item {
      .is-active-link:before {
        margin-top: 3px;
      }
    }
  }

  @media print {
    // show every item in the ToC list
    .isCollapsible {
      max-height: 100%;
    }

    .is-collapsed {
      max-height: 100%;
    }
  }
}

.heading-row {
  display: flex;
  align-items: center;
  margin: 0;
  padding: var(--space-025);
}

.heading-row .toc-link {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-sm);
  flex: 1;
  margin: 0;
  padding: 0;
  margin-right: 0.5em;
}

.toc-expand-btn {
  font-size: 12px;
  padding: 1em;
  margin: -1em;
  margin-right: 1em;
  color: var(--primary-color);
  opacity: 0.5;
  transition: transform 0.1s;
  transform: rotate(-90deg);
}

.toc-expand-btn:hover {
  cursor: pointer;
  text-decoration: none;
}

.expand-all-btn {
  padding: 0.5em;
  color: var(--primary-color);
  opacity: 0.5;
  font-size: var(--font-size-xs);
  transition: transform 0.1s;
  transform: rotate(90deg);
  &:hover {
    text-decoration: none;
  }
  &.all-expanded {
    transform: rotate(-90deg);
  }
}

/* This selects any list item that has a direct child <ol> with the class is-collapsed. 
Within that list item, this selects the expand button that is a child of .heading-row. */
// Rotates the expand button back to "closed" state when the list is collapsed
.toc-list-item:has(> ol.is-collapsed) > .heading-row > .toc-expand-btn {
  transform: rotate(90deg);
}