@use "../base";

.c-feedback-invite {
  margin: var(--space-x-small) 0;
  background-color: var(--sky-color);
  padding: var(--space-medium);
  position: relative;
  h2 {
    margin-right: var(--space-large);
    font-size: var(--heading-medium-font-size);
    display: flex;
    align-items: center;
    svg {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: var(--space-x-small);
    }
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .close {
    position: absolute;
    width: auto;
    top: var(--space-small);
    right: var(--space-small);
  }

  @media screen and (max-width: base.$renblad-tablet) {
    h2 svg {
      margin-right: var(--space-x-small);
    }
  }
}
