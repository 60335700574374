@use "../base";

.c-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

// Logo //
.c-menu__logo {
  padding: base.$spacingSmall 0;
  color: base.$colorBrightGreen;
}
.c-menu__logo a {
  text-decoration: none;
}

.c-menu__logo svg {
  width: 80px;
  height: auto;
}
// Make logo larger on mobile //
@media screen and (min-width: 700px) {
  .c-menu__logo svg {
    width: 111px;
  }
}

// Menu items  //
.c-menu__list-item {
  display: inline-block;
  padding-left: base.$spacing;
  padding-right: base.$spacing;
  list-style-type: none;
  font-size: var(--body-font-size);
  font-weight: 400;
  a:link,
  a:visited {
    color: base.$colorPineGreen;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  &:last-child {
    padding-right: 0;
  }
}

.c-menu__list-item-profile {
  vertical-align: middle;
}

// Hide menu items on small screens //
@media screen and (max-width: 1100px) {
  .c-menu__list-item:nth-child(1) {
    display: none;
  }
}

@media screen and (max-width: 975px) {
  .c-menu__list-item:nth-child(4) {
    display: none;
  }
  .c-menu__list-item-profile {
    display: none;
  }
}
@media screen and (max-width: 717px) {
  .c-menu__list-item-customizable {
    display: none;
  }
}

@media screen and (max-width: 40em) {
  .c-menu__list-item:nth-last-child(2) {
    padding-right: 0;
  }
}

// Button for opening dropdown menu //
.c-menu__button {
  font-size: 1em;
  color: base.$colorPineGreen;
  font-weight: 400;
}
.c-menu__button:hover {
  text-decoration: underline;
}

// Dropdown menu //
.c-menu__overlay {
  background-color: base.$colorDarkPine;
  width: 100%;
  display: block;
  height: 100vh;
  z-index: base.z-index(navigation);
  position: fixed;
  overflow-y: auto;
  left: 0;
  top: 0;
  margin-top: 0;
  font-size: 1.2rem;
}

// Button for closing menu//
.c-menu__overlay-button {
  padding-top: base.$spacing;
  color: base.$colorWhite;
  cursor: pointer;
  svg {
    height: 30px;
    width: 30px;
  }
}

.c-menu__overlay-button:hover {
  color: base.$colorLuminousGreen;
  fill: base.$colorLuminousGreen;
}
@media (max-width: 800px) {
}

// Adjust layout of dropdown menu on desktop//
@media (min-width: 1000px) {
  .c-menu__dropdown-content {
    display: flex;
    justify-content: space-evenly;
    padding-top: base.$spacingHuge;
  }
  .c-menu__dropdown-list--2column {
    column-count: 2;
  }
}

@media (min-width: 800px) {
  .c-menu__overlay-button {
    margin-right: base.$spacingLarge;
  }
}

@media (min-width: 1200px) {
  .c-menu__overlay-button {
    margin-top: 0;
    margin-right: base.$spacingLarge * 5;
  }
}

// Colum for main menu items in dropdown "Green" //
.c-menu__dropdown-main-menu {
  font-weight: 400;
  padding: 0;
  margin: 0;
  li {
    margin-top: 0;
    margin-bottom: base.$spacing;
  }

  a:link,
  a:visited {
    color: base.$colorBrightGreen;
    text-decoration: none;
  }
  a:hover {
    font-weight: 500;
    text-decoration: underline;
  }
}

// Colum for submenu items in dropdown "White" //
.c-menu__dropdown-list {
  font-weight: 400;
  padding: 0;
  margin: 0;
  li {
    margin-top: 0;
    margin-bottom: base.$spacing;
  }

  a:link,
  a:visited {
    color: white;
    text-decoration: none;
  }
  a:hover {
    font-weight: 500;
    text-decoration: underline;
  }
}

@media all and (-ms-high-contrast: none) {
  .c-menu__button {
    background-color: base.$colorWhite;
  }

  .c-menu__overlay-button {
    background-color: base.$colorPineGreen;
  }

  .c-menu__logo {
    /* Actual dimensions of logo SVG. IE11 has weird feature where svg container element adds additional height for no apparent reason */
    height: 28px;
    width: 111px;
  }
}
