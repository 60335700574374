@use "../../base";

.brukerguide-mobile-menu {
  display: none;
  flex-basis: 50%;
  flex-direction: row-reverse;

  @include base.no-aside {
    display: flex;
  }

  button {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      transform: rotate(180deg);
      width: 20px;
    }
  }

  .brukerguide-level-nav {
    background-color: var(--white-color);
    position: absolute;
    height: 1000px;
    left: 0;
    right: 0;
    top: calc(var(--space-300));
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: var(--space-050);
    padding: var(--space-100);

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--font-color);
      background-color: var(--tertiary-shade);
      padding: var(--space-050) var(--space-100);

      svg {
        width: 25px;
        height: 25px;
      }

      &.active {
        background-color: var(--primary-color);
        color: var(--white-color);
        margin-top: var(--space-100);
      }
    }

    .sub-pages {
      display: flex;
      flex-direction: column;
      gap: var (--space-050);
      margin-bottom: var(--space-100);

      a.sub-page {
        background-color: var(--tertiary-color);
      }
    }
  }

  .brukerguide-table-of-content {
    margin-top: var(--space-100);

    a {
      background: inherit;
      color: var(--primary-color);
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

// Headline and description text for brukerguide index page
.brukerguide-header {
  margin: var(--space-600) auto;
  max-width: var(--renblad-content-width);
  color: var(--primary-color);

  .title {
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: var(--space-200);
  }

  @include base.no-aside {
    margin-left: var(--space-200);
  }

  @include base.mobile {
    margin-left: var(--space-200);
    margin-right: var(--space-200);
  }
}

.brukerguide-top-text {
  color: var(--color-text-secondary);
  font-size: var(--font-size-heading-4);
  max-width: var(--renblad-content-width);
  font-weight: 400;
  margin-bottom: 0%;
}

.theme-brukerguide div.three-level-admin-page-header {
  margin-bottom: 30px;
}

.c-portableText {
  &.c-portableText--veiledning,
  &.c-portableText--elsikkerhet,
  &.c-portableText--henvisning-til-standard,
  &.c-portableText--ren-kommentar {
    padding-left: 20px;

    h2 {
      font-size: var(--font-size-heading-3);
      margin-bottom: var(--space-100);
    }

    h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: var(--font-size-heading-4);
    }

    p {
      margin-top: var(--space-50);
    }
  }

  &.c-portableText--forskrift {
    border-bottom: var(--dimension-border-default) solid
      var(--color-border-default);
    padding-bottom: var(--space-200);
    margin-bottom: var(--space-300);
    margin-top: var(--space);
  }

  &.c-portableText--veiledning {
    border-left: 4px solid var(--color-feedback-border-warning);

    h2 {
      color: var(--color-feedback-text-warning);
    }
  }

  &.c-portableText--elsikkerhet {
    border-left: 4px solid var(--color-feedback-border-error);

    h2 {
      color: var(--color-feedback-text-error);
    }
  }

  &.c-portableText--henvisning-til-standard {
    border-left: 4px solid var(--color-feedback-border-informative);

    h2 {
      color: var(--color-feedback-text-informative);
    }
  }

  &.c-portableText--ren-kommentar {
    border-left: 4px solid var(--color-feedback-border-positive);

    h2 {
      color: var(--color-feedback-text-positive);
    }
  }
}

.brukerguide__type-select {
  border-radius: var(--border-radius-default);
  border: var(--dimension-border-default) solid
    var(--input-color-border-default);
  background: var(--input-color-background-default);
  margin-left: var(--space-150);
  padding: var(--space-025, --space-075);
  gap: var(--space-075);
  height: var(--element-dimension-height-small);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
