@use "../base";
@use "sass:math";

.c-button-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.c-button-list p {
  font-size: var(--body-font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  margin-block: 1em;
}
.c-button-list li {
  margin-top: 0;
  margin-bottom: 0;
}

.c-button-list__item {
  background-color: base.$colorPistacchio;
  padding: 0.8em base.$spacing;
  display: flex;
  justify-content: space-between;
  margin: 0;
  color: base.$colorBlack;
  font-weight: 500;
  transition: transform 0.15s ease-in-out;
}

.c-button-list__item:hover {
  transform: translate(
    math.div(base.$spacing, 4),
    math.div(base.$spacingNegative, 4)
  );
}
