@use "../base";

.c-rich-text-list-view {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;

  /* reset c-portableText rules */
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__separator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-x-small);
    margin-bottom: var(--space-x-small);
    border-top: 1px solid var(--tertiary-color);
  }

  &__list {
    margin: 0;
    li {
      margin-bottom: 0;
    }
  }

  &__item {
    line-height: var(--line-height);
  }

  &__controls {
    height: calc(2 * var(--heading-small-font-size));
    width: calc(2 * var(--heading-small-font-size));

    button {
      border: 0;
      border-radius: 50%;
      background: transparent;
      height: var(--heading-small-font-size);
      width: var(--heading-small-font-size);
      align-items: center;
      justify-content: center;

      svg {
        height: var(--heading-small-font-size);
        width: var(--heading-small-font-size);
      }

      &:focus {
        outline: none;
        border: none;
      }

      &:hover {
        background: transparent;
      }
    }
  }

  &__popover-content {
    display: flex;
    flex-direction: column;
    gap: var(--space-x-small);
    border-radius: var(--space-xxx-small);
    padding: base.$spacing;
    min-width: base.$spacingHuge;
    background-color: var(--bg-color);
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

    &:focus {
      box-shadow:
        hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
        0 0 0 2px var(--primary-color);
    }
  }
}
