@use "../base";

.c-frontpage-hero {
}

.c-frontpage-hero__lede {
  text-align: center;
  font-weight: normal;
  padding: base.$spacing base.$spacingTiny;
}

.c-frontpage-hero__image {
  margin-top: var(--space-medium);
  width: 100%;
  height: auto;
}

.c-frontpage-hero__image--tablet {
  display: none;
}

.c-frontpage-hero__image--desktop {
  display: none;
}
@media (min-width: 600px) {
  .c-frontpage-hero__image--mobile {
    display: none;
  }
  .c-frontpage-hero__image--tablet {
    display: block;
  }
  .c-frontpage-hero__lede {
    position: relative;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: -30%;
    margin-top: 10%;
    padding: base.$spacingTiny;
    background-color: base.$colorWhite;
    border-radius: 5px;
  }
}
@media (min-width: 700px) {
  .c-frontpage-hero__lede {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media (min-width: 800px) {
  .c-frontpage-hero__lede {
    margin-left: 16%;
    margin-right: 16%;
    margin-top: 12%;
  }
}

@media (min-width: 1000px) {
  .c-frontpage-hero__image--tablet {
    display: none;
  }
  .c-frontpage-hero__image--desktop {
    display: block;
  }
  .c-frontpage-hero__lede {
  }

  .c-frontpage-hero__lede {
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: -20%;
    margin-top: 5%;
    background-color: transparent;
  }
}
