@use "../base";

.c-status {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.c-status-inactive {
  display: none;
}

.c-status-success {
  color: #155724;
  background-color: base.$colorPistacchio;
  border-color: base.$colorPineGreen;
}

.c-status-fail {
  background-color: base.$colorPlum;
}
