@use "../base";

.c-advertisement {
  border-top: 5px solid var(--fog-color);
  border-bottom: 5px solid var(--fog-color);
  position: relative;

  padding-top: base.$spacingSmall;
  padding-bottom: 1rem;

  img {
    display: block;
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
  }
}

.c-advertisement--wide {
  grid-column: span 2;
}

.c-advertisement-card__tag {
  position: absolute;
  top: calc(var(--space-small) * -1);
  font-size: base.$fontSmall;
  font-weight: 500;
  background-color: var(--grey-sky-color);
  padding-right: var(--space-xx-small);
  color: var(--black-color);
  text-transform: uppercase;
  line-height: 1;
}
