@use "../base";

.c-renblad {
  .c-companyComments {
    margin-top: base.$spacing;

    margin-bottom: base.$spacing;
    padding: base.$spacingSmall;
    max-width: 60rem;
    border-left: 10px solid #e0e0e0;

    .ownCompanyComments {
      p#publiseringsDato {
        margin-top: 0;
      }
    }

    .no-notes {
      line-height: 1rem;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: base.$spacingSmall;
    }

    h4 {
      font-size: 1.2rem;
      margin-top: base.$spacing;
    }
  }
}
