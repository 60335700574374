@use "../base";

.c-header__title {
  line-height: 1.4;
  font-weight: 500;
}

.c-header {
  padding-top: base.$spacingLarge;
  padding-bottom: base.$spacingLarge;
  min-height: 110px;
  &__form-button {
    margin-bottom: -(base.$spacingLarge);
  }

  &__inner {
    max-width: var(--max-content-width);
    margin-inline: auto;
  }
}

.c-header p {
  margin-block: 1em;
}

.c-header--slim {
  min-height: 0;
  padding-top: base.$spacingSmall;
  padding-bottom: base.$spacingSmall;

  .c-header__title {
    padding-top: base.$spacingTiny;
  }

  .c-header__lede {
    margin-top: base.$spacingSmall;
    margin-bottom: base.$spacingSmall;
  }
}

.c-header--default,
.c-header--event {
  background-color: base.$colorPineGreen;
  color: base.$colorWhite;
}

.c-header--white {
  color: base.$colorPineGreen;
}

.c-header--yellow {
  background-color: base.$colorSunny;
  color: base.$colorPineGreen;
}

.c-header__lede {
  font-size: base.$fontLarger;
  line-height: 1.5;
  a {
    text-decoration: underline;
    color: base.$colorPineGreen;
  }
  margin: 0;
  margin-top: base.$spacing;
  margin-bottom: base.$spacing;
}

.c-header--white,
.c-header--yellow {
  .c-header__lede {
    font-size: base.$fontLarge;
    line-height: 1.5;
    font-weight: 400;
  }
}

.c-header__with-illustration {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 2rem;

  .illustration {
    align-self: center;
    img {
      width: 100%;
    }
  }
}

.byline {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

@media (min-width: 60em) {
  .c-header__title {
    padding-top: base.$spacingLarge;
  }
  .c-header--event {
    .c-header__inner {
      max-width: unset;
    }
    .c-header__title {
      flex-basis: 54%;
      max-width: 54%;
    }
  }
}

@media (max-width: 60em) {
  .c-header {
    padding: base.$spacing 0;
    &__lede {
      font-size: base.$fontLarge;
    }
    &__form-button {
      margin-bottom: -(base.$spacing);
    }
    &__inner {
      // Used to move title down on Event pages.
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
