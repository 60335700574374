@use "../base";

.c-link-preview {
  .c-link-preview-popover {
    width: base.$narrowWidth - 340px;
    height: base.$narrowWidth - 340px;
    padding: base.$spacingTiny base.$spacing;
    overflow-y: auto;
  }

  &.c-link-preview-outline:focus {
    outline: base.$colorDarkClouds solid 1px;
  }

  .c-link-preview-tag {
    margin: base.$spacing 0 base.$spacingTiny 0;
  }

  & div.c-renblad-content {
    min-width: 100%;
  }
}

.c-link-preview-icon {
  vertical-align: text-bottom;
}
