/* Core Styles */

// To leverage the native smooth-scrolling by the browser (currently available in Firefox 36+ and Chrome 49+)
// body, .smooth-container { scroll-behavior: smooth }

.toc {
  overflow-y: auto;
  cursor: pointer;
}

span.o-label-uppercase {
  margin-bottom: 1em;
  border-bottom: 0.1px solid var(--primary-color);
  display: flex;
  align-items: center;
  gap: 1em;
}

.c-toc__content {
  scrollbar-gutter: stable;
  overscroll-behavior: contain;
}

.toc > .toc-list {
  overflow: hidden;
  position: relative;

  li {
    list-style: none;
  }
}

.js-toc {
  overflow-y: hidden;
}

.c-toc__content {
  border: none;
  margin: 0;
  padding-left: 1em;
  .toc-list {
    padding: 0;
  }
}

// Add margin to outermost toc-list to show last items in large TOC on smaller screens
.c-toc__content > .toc-list {
  margin-bottom: 10em;
}


/* Give sub-lists a dashed vertical line on the left, plus indentation */
ol.toc-list .is-collapsible {
  margin-left: 1em;        /* indent each nested list */
}

.toc-list-item {
  margin: var(--space-025) 0 var(--space-025) 0;
}

.toc-list-item > ol.toc-list {
  padding-left: 1rem;
  border-left: solid 2px #eee;
}

a.toc-link {
  color: currentColor;
  height: 100%;
  cursor: pointer;
}

.toc-link:hover {
  text-decoration: underline;
}

.active-link {
  font-weight: 500;
  position: relative;
}

.toc-list .active-link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.9em;
  width: 1em;
  border-left: solid 2px var(--primary-color);
}

.toc-list .toc-list .active-link::before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1.25em; // Slightly more would be better aesthetically, but it causes it to disappear am unable to find a fix.
  width: 1em;
  border-left: 2px solid var(--primary-color);
}

.is-collapsible {
  overflow: hidden;
  transition: all 300ms ease-in-out;
}

.is-collapsed {
  max-height: 0;
}

.is-position-fixed {
  position: fixed !important;
  top: 0;
}

.is-active-link {
  font-weight: 500;
}
