@use "../base";

.c-footer {
  background-color: base.$colorDarkPine;
  color: base.$colorWhite;

  font-weight: 300;
}

.c-footer p {
  font-size: var(--body-font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  margin-block: 1em;
}

.c-footer {
  a:link,
  a:visited {
    color: base.$colorBrightGreen;
    border-bottom: 2px solid currentColor;
  }
  a:hover {
    font-weight: 500;
  }
}

.c-footer__some-link {
  margin-bottom: base.$spacingSmall;

  a:link,
  a:visited {
    color: base.$colorWhite;
    border-bottom: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

.c-footer__some-icon {
  margin-right: base.$spacingSmall;
  vertical-align: middle;
}

a:link.c-footer__newsletter,
a:visited.c-footer__newsletter {
  color: base.$colorPineGreen;
  font-weight: 500;
}

.c-footer__arrow {
  margin-left: 10px;
  vertical-align: sub;
  color: base.$colorPineGreen;
}

.c-footer__newsletter {
  background-color: base.$colorWhite;
  font-size: 0.9rem;
  padding: 12px 24px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.c-footer__newsletter:hover {
  background-color: base.$colorLuminousGreen;
  .c-footer__arrow {
    color: base.$colorPineGreen;
  }
}

@media screen and (max-width: 60em) {
  .c-footer__header {
    font-size: base.$fontLarge;
  }
  .c-footer__newsletter {
    padding: 12px 24px;
    svg {
      margin-bottom: -4px;
    }
  }
}
