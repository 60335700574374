@use "../base";

.checkbox-container {
  position: relative;
  margin-bottom: 15px;
}

.checkbox-updating {
  display: flex;
  align-items: center;
  margin-left: 35px;
  margin-top: 3px;
  font-size: 0.9em;
  color: var(--green-dark-color);
  
  .loading-spinner {
    padding: 0;
    margin-right: 5px;
  }
  
  span {
    font-style: italic;
  }
}
