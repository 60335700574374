@use "../base";

.c-renblad-part-menu {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  margin-top: base.$spacing;
  margin-bottom: -(base.$spacingLarge);
  padding-bottom: base.$spacingLarge;
  &__divider {
    flex-grow: 1;
    align-self: center;
    margin-top: base.$spacingSmall;
    padding-bottom: base.$spacingSmall;
    border-top: 1px solid base.$color3000Secondary;
  }
  &__actions {
    display: flex;
    gap: base.$spacingSmall;
    flex-flow: row nowrap;
    overflow: hidden;
    margin-right: base.$spacingSmall;
    border: 1px solid base.$color3000Secondary;
    box-sizing: border-box;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    &__divider {
      display: inline;
      border-left: 1px solid base.$color3000TertiaryShade;
      margin: base.$spacingSmall (-(base.$spacingTiny));
    }
    button {
      padding: base.$spacingSmall;
    }
    button:hover {
      background-color: base.$colorFog;
    }
  }
}

.c-renblad-part-menu:hover + * {
  background-color: base.$colorFog;
}

.c-renblad-part-menu:hover {
  background-color: base.$colorFog;
}

.renbladpart-metadata {
  display: flex;
}
