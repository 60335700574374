@use "sass:map";

$fontTiny: 0.8rem;
$fontSmall: 0.9rem;
$font: 1rem;
$fontLarge: 1.4rem;
$fontLarger: 1.8rem;
$fontLargest: 2.744rem;
$fontHuge: 3.842rem;

$narrowWidth: 720px;
$mediumWidth: 1000px;
$siteWidth: 1180px;
$wideWidth: 1300px;

$spacingTiny: 0.27rem; /* 5px */
$spacingSmall: 0.5rem; /* 10px */
$spacing: 1.1rem; /* 20px */
$spacingNegative: -1.1rem; /* 20px */
$spacingLarge: 2.2rem; /* 40px */
$spacingLarger: 4.4rem; /* 80px */
$spacingHuge: 8.8rem; /* 160px */

//intended for z-index usage
$z-index: (
  above-top: 900,
  top: 500,
  modal: 200,
  navigation: 100,
  footer: 90,
  content-top: 51,
  content: 50,
);

@function z-index($key) {
  @return map.get($z-index, $key);
}

//new colors
$colorBlack: #1e1f21;

$colorPineGreen: #0a6252;
$colorPineGreenOpacity: rgba(
  15,
  72,
  62,
  0.7
); // same as pinegreen with 70% opacity https://www.hexcolortool.com/#0f483d,0.7
$colorBrightGreen: #1be066;
$colorLuminousGreen: #2afc7a;
$colorPistacchio: #dcf9da;
$colorDarkPine: #0f483e;
$colorSoftGreen: #44938d; // TODO: Ikke i Figma

$colorSunny: #fee887;
$colorPlum: #980338;
$colorMagenta: #d81b60;
$colorVanilla: #fff9e2;

$colorAir: #f3fcff;
$colorPinkSkin: #ffefef;
$colorPalePurple: #faf5ff;

$colorRoyalBlue: #153f7d;
$colorLilac: #826f96;
$colorDarkPurple: #3e2a53;

$colorSky: #c0ecf5;
$colorEveningSky: #287daf;

$colorDarkClouds: #5d666f;
$colorFog: #e5e5e5;
$colorGreySky: #faf7f7;
$colorWhite: #ffffff;

//Series colors:
$color1000Primary: #7c2a0d;
$color1000Secondary: #ac360e;
$color1000Tertiary: #f3e0d9;
$color1000TertiaryShade: #dcb5a7;

$color2000Primary: #0c4c60;
$color2000Secondary: #117fa0;
$color2000Tertiary: #cce9f2;
$color2000TertiaryShade: #a0c4cf;

$color3000Primary: #241e1e;
$color3000Secondary: #4e4e4e;
$color3000Tertiary: #dadada;
$color3000TertiaryShade: #b9b3b3;

$color4000Primary: #39770f;
$color4000Secondary: #59ac20;
$color4000Tertiary: #d8edca;
$color4000TertiaryShade: #b5d99d;

$color5000Primary: #0e2b7c;
$color5000Secondary: #1d48be;
$color5000Tertiary: #dde6fc;
$color5000TertiaryShade: #b8c1d8;

$color6000Primary: #9c6902;
$color6000Secondary: #d69510;
$color6000Tertiary: #ebe3d2;
$color6000TertiaryShade: #dbcdb0;

$color7000Primary: #59035a;
$color7000Secondary: #8e0b90;
$color7000Tertiary: #ecdaec;
$color7000TertiaryShade: #d4b3d4;

$color8000Primary: #8e0629;
$color8000Secondary: #ca0034;
$color8000Tertiary: #fde9ee;
$color8000TertiaryShade: #fdc5d4;

$color9000Primary: #055d63;
$color9000Secondary: #048c95;
$color9000Tertiary: #cff3ed;
$color9000TertiaryShade: #83cec1;

// Breakpoints
$breakpoint-small-mobile: 24rem; // 384px
$breakpoint-medium-tablet: 50rem; // 800px

// RENblad breakpoints
$renblad-small-mobile: 24rem; // 384px
$renblad-mobile: 27rem; // 432px
$renblad-tablet: 33rem; // 528px
$renblad-font-breakpoint: 50rem; // 800px
$renblad-no-aside: 66rem; // 1056px
$renblad-no-right-margin: 75rem; // 1200px
$renblad-desktop: 90rem; // 1440px

@mixin mobile {
  @media screen and (max-width: $renblad-mobile) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $renblad-font-breakpoint) {
    @content;
  }
}

@mixin no-aside {
  @media screen and (max-width: $renblad-no-aside) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $renblad-desktop) {
    @content;
  }
}

:root {
  // skeleton for default theme
  --primary-color: #{$colorPineGreen};
  --secondary-color: #{$colorBrightGreen};
  --tertiary-color: #{$colorBrightGreen};
  --tertiary-shade: #{$colorDarkPine};
  --font-color: #{$colorBlack};
  --bg-color: #{$colorWhite};
  --heading-color: #292922;

  // colors:
  --black-color: #{$colorBlack};
  --pine-green-color: #{$colorPineGreen};
  --bright-green-color: #{$colorBrightGreen};
  --louminous-green-color: #{$colorLuminousGreen};
  --pistacchio-color: #{$colorPistacchio};
  --dark-pine-color: #{$colorDarkPine};
  --vanilla-color: #{$colorVanilla};
  --sunny-color: #{$colorSunny};

  --warning-red-color: #ff0000;
  --info-yellow-color: #ffd600;
  --ok-green-color: #00ad11;

  --plum-color: #{$colorPlum};
  --magenta-color: #{$colorMagenta};
  --pink-skin-color: #{$colorPinkSkin};

  --air-color: #{$colorAir};
  --pale-purple-color: #{$colorPalePurple};
  --dark-purple-color: #{$colorDarkPurple};
  --royal-blue-color: #{$colorRoyalBlue};
  --lilac-color: #{$colorLilac};
  --evening-sky-color: #{$colorEveningSky};
  --sky-color: #{$colorSky};

  --grey-sky-color: #{$colorGreySky};
  --white-color: #{$colorWhite};
  --fog-color: #{$colorFog};
  --dark-clouds-color: #{$colorDarkClouds};

  // Temp colors TODO: Update names when design tokens are implemented
  --color-background-default: #ffffff;
  --color-border-default: #9cadb0;
  --color-layer-hover-1: #f2f6f7;
  --color-layer-default-2: #f2f6f7;
  --color-text-primary: #142b2f;

  --color-feedback-text-informative: #003c65;
  --color-feedback-border-informative: #4f95ba;
  --color-feedback-layer-informative: #c0ecf5;

  --color-feedback-text-positive: #014138;
  --color-feedback-border-positive: #02a15f;
  --color-feedback-layer-positive: #a7f1b2;

  --color-feedback-text-warning: #483000;
  --color-feedback-border-warning: #b68c01;
  --color-feedback-layer-warning: #fee887;

  --color-feedback-text-error: #6c002b;
  --color-feedback-border-error: #e74a76;
  --color-feedback-layer-error: #ffd0d7;

  --button-color-background-primary-default: #015e4c;
  --button-color-background-secondary-default: #fff;

  --button-color-text-primary-default: #f2fef1;
  --button-color-text-secondary-default: #142b2f;

  --button-color-background-secondary-hover: #f2f6f7;
  --button-color-text-secondary-hover: #142b2f;

  --button-color-background-primary-hover: #017e5b;
  --button-color-text-primary-hover: #f2fef1;

  --button-color-border-secondary-default: #9cadb0;
  --button-color-border-secondary-hover: #60757a;

  --color-brand-text-primary: #015e4c;

  // Fonts
  --font-size: 1rem;
  --line-height: 150%;
  --font-weight: 300;
  --font-bold-weight: 500;
  --font-bolder-weight: 700;
  --heading-line-height: 125%;
  --heading-largest-font-size: 1.875rem; // 30px
  --heading-large-font-size: 1.5rem; // 24px
  --heading-medium-font-size: 1.25rem; // 20px
  --heading-small-font-size: 1rem; // 16px
  --table-row-title-font-size: 0.875rem; // 14px
  --table-row-undertitle-font-size: 0.875rem; // 14px
  --label-font-size: 0.875rem; // 14px
  --lead-font-size: 1.25rem; // 20px
  --toc-font-size: 1rem; // 16px
  --body-font-size: 1rem; // 16px
  --body-link-font-size: 1rem; // 16px
  --caption-font-size: 0.875rem; // 14px
  --button-font-size: 1.125rem; // 18px

  // Outline
  --outline-stroke: 1px solid var(--black-color);

  // border-radius
  --dimension-border-default: 1px;
  --border-radius-default: 2px;

  // spacing
  --space-xxx-small: 0.25rem; // 4px
  --space-xx-small: 0.5rem; // 8px
  --space-x-small: 1rem; // 16px
  --space-small: 1.5rem; // 24px
  --space-medium: 2rem; // 32px
  --space-large: 3.5rem; // 56px
  --space-x-large: 5rem; // 80px
  --space-xx-large: 6rem; // 96px
  --space-xxx-large: 9rem; // 144px
  --space-xxxx-large: 12.5rem; // 200px

  // Website structure
  --max-content-width: 50rem; // 800px

  // RENblad structure
  --renblad-max-width: #{$renblad-desktop};
  --renblad-content-padding-left: var(--space-x-large);
  --renblad-content-margin-right: 11rem;
  --renblad-content-width: 45rem;
  --renblad-aside-width: 24.75rem;
  --renblad-aside-min-width: 14rem;
  --renblad-aside-horizontal-padding: var(--space-small);
  --renblad-menu-seriesnumber-padding: var(--space-medium);
  --renblad-menu-height: 60px;
  --renblad-mobile-toc-height: 50px;

  @media screen and (min-width: $renblad-font-breakpoint) {
    --font-size: 1.1875rem; // 19px
    --heading-largest-font-size: 2.25rem; // 36px
    --heading-large-font-size: 1.875rem; // 30px
    --heading-medium-font-size: 1.375rem; // 22px
    --heading-small-font-size: 1.1875rem; // 19px
    --table-row-title-font-size: 1rem; // 16px
    --table-row-undertitle-font-size: 1rem; // 16px
    --label-font-size: 1rem; // 16px
    --lead-font-size: 1.5rem; // 24px
    --body-font-size: 1.1875rem; // 19px
    --body-link-font-size: 1.1875rem; // 19px
    --caption-font-size: 1rem; // 16px
    --button-font-size: 1.125rem; // 18px
  }

  @media screen and (max-width: $renblad-desktop) {
    --renblad-content-margin-right: var(--space-x-large);
  }

  @media screen and (min-width: $renblad-no-aside) and (max-width: $renblad-no-right-margin) {
    --renblad-aside-width: 15rem;
  }

  @media screen and (max-width: $renblad-no-right-margin) {
    --renblad-content-padding-left: var(--space-medium);
    --renblad-content-margin-right: var(--space-medium);
  }

  @media screen and (max-width: $renblad-mobile) {
    --renblad-content-padding-left: var(--space-x-small);
    --renblad-content-margin-right: var(--space-x-small);
    --renblad-menu-seriesnumber-padding: var(--space-x-small);
  }

  @media screen and (max-width: $renblad-no-aside) {
    --renblad-aside-horizontal-padding: var(--space-x-small);
  }
}

// Uses Robot Flex, css variable set and made available in <FontWrapper />
// TODO: Remove the need to set this on *?
* {
  font-family: var(--font-family-sans), sans-serif;
}

// This overrides reset from Design-system/Tailwind
svg {
  display: unset;
  vertical-align: unset;
}

[tabindex]:focus {
  outline: auto;
}

// RENblad themes
.renblad-menu-container {
  background-color: var(--tertiary-shade);

  color: var(--primary-color);
}

.theme-1000 {
  --primary-color: #{$color1000Primary};
  --secondary-color: #{$color1000Secondary};
  --tertiary-color: #{$color1000Tertiary};
  --tertiary-shade: #{$color1000TertiaryShade};
}

.theme-2000 {
  --primary-color: #{$color2000Primary};
  --secondary-color: #{$color2000Secondary};
  --tertiary-color: #{$color2000Tertiary};
  --tertiary-shade: #{$color2000TertiaryShade};
}

.theme-3000 {
  --primary-color: #{$color3000Primary};
  --secondary-color: #{$color3000Secondary};
  --tertiary-color: #{$color3000Tertiary};
  --tertiary-shade: #{$color3000TertiaryShade};
}
.theme-4000 {
  --primary-color: #{$color4000Primary};
  --secondary-color: #{$color4000Secondary};
  --tertiary-color: #{$color4000Tertiary};
  --tertiary-shade: #{$color4000TertiaryShade};
}
.theme-5000 {
  --primary-color: #{$color5000Primary};
  --secondary-color: #{$color5000Secondary};
  --tertiary-color: #{$color5000Tertiary};
  --tertiary-shade: #{$color5000TertiaryShade};
}
.theme-6000 {
  --primary-color: #{$color6000Primary};
  --secondary-color: #{$color6000Secondary};
  --tertiary-color: #{$color6000Tertiary};
  --tertiary-shade: #{$color6000TertiaryShade};
}
.theme-7000 {
  --primary-color: #{$color7000Primary};
  --secondary-color: #{$color7000Secondary};
  --tertiary-color: #{$color7000Tertiary};
  --tertiary-shade: #{$color7000TertiaryShade};
}

.theme-8000 {
  --primary-color: #{$color8000Primary};
  --secondary-color: #{$color8000Secondary};
  --tertiary-color: #{$color8000Tertiary};
  --tertiary-shade: #{$color8000TertiaryShade};
}
.theme-9000 {
  --primary-color: #{$color9000Primary};
  --secondary-color: #{$color9000Secondary};
  --tertiary-color: #{$color9000Tertiary};
  --tertiary-shade: #{$color9000TertiaryShade};
}
.theme-planbok {
  --primary-color: #003c65;
  --secondary-color: #4db77c;
  --tertiary-color: #dcf9da;
  --tertiary-shade: #95f9b3;
}

.theme-brukerguide {
  --primary-color: #0a2d29;
  --secondary-color: #4db77c;
  --tertiary-color: #dcf9da;
  --tertiary-shade: #95f9b3;
}

.theme--default {
  --primary-color: #0a6252;
  --secondary-color: #0d8a73;
  --tertiary-color: #c8efc9;
  --tertiary-shade: #91e093;
}
