@use "../../base";

.theme-planbok .c-portableText--renblad > .c-renblad-content {
  // Planbok header is slightly larger than RENblad
  scroll-margin-top: 7rem;
}

.cards-section {
  background-color: var(--tertiary-color);
  padding: var(--space-x-large) 0px;
}

.planbok-background {
  background-color: var(--tertiary-color);
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-small);
  margin: var(--space-x-large) auto;
  max-width: 50rem;

  @include base.mobile {
    flex-direction: column;
  }

  a {
    flex-grow: 1;
  }

  p {
    margin-bottom: 20px;
  }
}

.planbok {
  max-width: var(--renblad-max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  background-color: var(--white-color);

  @include base.no-aside {
    flex-direction: column;
    padding: 0 var(--space-x-small);
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: var(--space-x-small);
    margin: var(--space-x-large) 0px;

    a {
      color: var(--primary-color);
      text-decoration: underline;
      display: flex;
      align-items: center;
      gap: var(--space-xx-small);
      font-size: var(--button-font-size);
      text-underline-offset: 6px;
      text-decoration-thickness: 2px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.planbok-top {
  width: 100%;

  background-color: var(--tertiary-shade);

  .planbok-top-wrapper {
    display: flex;
    max-width: var(--renblad-max-width);
    margin: 0 auto;
    height: var(--space-xx-large);
    /* Position is for some unknown reason needed to make box-shadow visible. */
    position: relative;

    &.editmode-enabled {
      box-shadow: 0 var(--space-x-small) var(--pine-green-color);
    }

    @include base.no-aside {
      height: var(--space-large);
    }
  }

  .logo {
    background-color: var(--primary-color);
    flex-basis: var(--renblad-aside-width);
    display: flex;
    align-items: flex-end;
    color: var(--white-color);
    flex-shrink: 0;
    padding: 8px;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 1.2px;
    justify-content: center;

    @include base.no-aside {
      flex-basis: 50%;
      align-items: center;
    }

    &-main {
      font-weight: 900;
    }

    &-ext {
      font-weight: 500;
      @include base.no-aside {
        display: none;
      }
    }
  }

  .planbok-menu {
    flex-basis: 70%;
    display: flex;
    align-items: flex-end;
    padding: 0px var(--space-medium);
    gap: var(--space-medium);

    @include base.no-aside {
      display: none;
    }

    a {
      padding: var(--space-x-small) 0px;
      color: var(--font-color);

      &:hover,
      &.active {
        border-bottom: 4px solid var(--primary-color);
        padding-bottom: calc(var(--space-x-small) - 4px);
      }
    }
    .renblad-menu__edit-status {
      margin-left: auto;
    }
  }
  .planbok-mobile-menu {
    display: none;
    flex-basis: 50%;

    flex-direction: row-reverse;

    @include base.no-aside {
      display: flex;
    }

    button {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        transform: rotate(180deg);
        width: 20px;
      }
    }

    .planbok-level-nav {
      background-color: var(--white-color);
      position: absolute;
      height: 1000px;
      left: 0;
      right: 0;
      top: calc(var(--space-large));
      bottom: 0;
      z-index: 10;

      display: flex;
      flex-direction: column;
      gap: var(--space-xx-small);

      padding: var(--space-x-small);

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--font-color);
        background-color: var(--tertiary-shade);
        padding: var(--space-xx-small) var(--space-x-small);

        svg {
          width: 25px;
          height: 25px;
        }

        &.active {
          background-color: var(--primary-color);
          color: var(--white-color);
          margin-top: var(--space-x-small);
        }
      }
      .sub-pages {
        display: flex;
        flex-direction: column;
        gap: var(--space-xx-small);
        a.sub-page {
          background-color: var(--tertiary-color);
        }
        margin-bottom: var(--space-x-small);
      }
    }
    .planbok-table-of-content {
      margin-top: var(--space-x-small);
      a {
        background: inherit;
        color: var(--primary-color);
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }
}

.planbok-sidemenu {
  flex-basis: var(--renblad-aside-width);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  padding: var(--space-x-large) var(--space-medium);

  @include base.no-aside {
    display: none;
  }

  a {
    display: block;
    color: var(--font-color);
    background-color: var(--tertiary-color);
    padding: var(--space-xx-small) var(--space-x-small);
    font-weight: 500;

    &:hover,
    &.active {
      background-color: var(--dark-pine-color);
      color: var(--tertiary-color);
    }
  }
}
