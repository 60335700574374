@use "../base";

.c-checklist-list--heading {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.c-checklist-list--single-wrapper {
  background-color: base.$colorWhite;
  margin-bottom: base.$spacingSmall;
  margin-top: base.$spacingSmall;
  padding: base.$spacing;
}

.c-checklist-list--single {
  color: base.$colorBlack;
  display: flex;
  align-items: center;
  transition: 0.15s;
  cursor: pointer;
}

.c-checklist-list--header {
  display: flex;
  margin-top: base.$spacingLarge;
  //font-size: $fontSmall;
  //text-transform: uppercase;
  padding: 0 base.$spacing;
}
.c-checklist-list--header--40 {
  margin: 0;
  width: 40%;
}
.c-checklist-list--header--20 {
  width: 20%;
  margin: 0;
}

.c-checklist-list--single__title {
  cursor: pointer;
  margin: 0;
  width: 40%;
  font-weight: 500;
}
.c-checklist-list--single__subtitle {
  font-weight: 500;
  font-size: base.$font;
  margin-top: base.$spacingLarge;
}

.c-checklist-list--single__category {
  width: 20%;
  margin: 0;
}
.c-checklist-list--single__arrow {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  width: 40%;
  color: base.$colorBrightGreen;
}
@media (max-width: 60em) {
  .c-checklist-list--single {
    flex-direction: column;
    align-items: flex-start;
  }

  .c-checklist-list--single__title {
    width: 100%;
    font-weight: 500;
  }

  .c-checklist-list--single__category {
    width: 100%;
  }

  .c-checklist-list--single__arrow {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: base.$spacingNegative;
    margin-left: base.$spacingNegative;
  }
  .c-checklist-list--header {
    display: none;
  }
}
.c-checklist-list--table {
  font-size: base.$fontSmall;
  font-weight: lighter;
}

.c-checklist-list--table-head {
  display: none;
}
.c-checklist-list--table-title {
  margin-top: base.$spacingSmall;
  margin-bottom: base.$spacingSmall;
}

.c-checklist-list--table-item {
  margin-top: base.$spacing;
  margin-bottom: base.$spacing;
}

@media (min-width: 60em) {
  .c-checklist-list--table-title {
    display: none;
  }
  .c-checklist-list--table {
    display: flex;
    justify-content: space-between;
  }
  .c-checklist-list--table-head {
    display: flex;
    justify-content: space-between;
  }
  .c-checklist-list--table-item--20 {
    width: 20%;
  }
  .c-checklist-list--table-item--50 {
    width: 50%;
  }
}

.c-checklist-list--single__linklist {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}

.c-checklist-list--single__link {
  color: base.$colorPineGreen;
  display: inline;
  margin-right: base.$spacingLarge;
}
.c-checklist-list--single__link:hover {
  font-weight: 500;
  text-decoration: underline;
}

.c-checklist-list--single__link-icon {
  vertical-align: middle;
  padding-left: base.$spacingSmall;
}

.c-checklist-list--single__li:first-child > .c-checklist-list--single__link {
  background-color: base.$colorBrightGreen;
  color: base.$colorPineGreen;
  text-align: center;
  font-size: base.$font;
  padding: base.$spacingSmall base.$spacing;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.c-checklist-list--single__li:first-child
  > .c-checklist-list--single__link:hover {
  color: base.$colorPineGreen;
  background-color: base.$colorLuminousGreen;
}
