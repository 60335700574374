@use "../base";

.single-activity {
}

.html-activity-wrapper {
  display: flex;
  align-items: center;
  border-radius: 0px;
  justify-content: space-between;
  padding-left: base.$spacing;
}

.html-activity--date {
  margin: 0 5px;
  flex-basis: 10%;
}

.html-activity--context {
  margin: 0 5px;
  flex-basis: 10%;
}
.html-activity--title {
  margin: 0 5px;
  flex-basis: 30%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--font-color);
}

.html-activity--link {
  flex-basis: 10%;
  justify-content: center;
  display: flex;
  > svg {
    stroke: var(--primary-color);
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;

    &:hover {
      stroke: var(--secondary-color);
    }
  }
}
