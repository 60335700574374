@use "../base";

.c-checklist-search input[type="text"] {
  padding: base.$spacingSmall base.$spacing;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: inherit;
  font-family: var(--font-family-sans), sans-serif;
  font-weight: 300;
  margin-right: base.$spacingTiny;
}
