@use "../base";

.c-event-info {
  margin-top: base.$spacing;
}

@media (min-width: 60em) {
  .c-event-info {
    margin: base.$spacingNegative * 8 base.$spacing base.$spacing base.$spacing;
  }

  .c-event-info__sidetext {
    flex-basis: 54%;
    max-width: 54%;
  }

  .c-event-info__container {
    flex-basis: 43%;
    max-width: 43%;
  }
  .c-event__sign-up {
    margin-top: base.$spacingTiny;
    margin-bottom: base.$spacingTiny;
  }
}

.c-event-info__main--margin-bottom {
  margin-bottom: base.$spacing;
}

.c-event-info__card-employee {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

.c-event-info__main {
  background-color: base.$colorFog;
  padding: base.$spacingLarge;
  font-size: var(--font-size);
}

.c-event-info__email {
  color: inherit;
  text-decoration: underline;
  &:hover {
    color: base.$colorPineGreen;
  }
}

.c-event-info__main--contact-rm-st {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 0;
  li {
    margin: 0;
  }
}
.c-event-info__main--contact {
  line-height: 1.7;
  font-weight: 300;
}
.c-event-info__main--contact::before {
  content: "";
}

.c-event-info--bold {
  font-weight: 500;
}

.c-event__sign-up {
  color: base.$colorPineGreen;
  width: 100%;
}

.c-event__sign-up:hover {
  cursor: pointer;
}

.c-event__sign-up--flex {
  margin: base.$spacingTiny 0;
  background-color: base.$colorBrightGreen;
  color: base.$colorBlack;
  padding: base.$spacing base.$spacingLarge;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: base.$colorLuminousGreen;
  }
}

.c-event-info__sidetext h2 {
  font-weight: 500;
  color: base.$colorPineGreen;
  font-size: 24px;
  margin-top: base.$spacingLarge;
}

.c-event-info ul {
  list-style: none;
}
