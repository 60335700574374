@use "../base";

.gray-cell {
  border: 1px solid rgba(150, 150, 150, 1);
  background: rgba(200, 200, 200, 0.4);
}

.blue-cell {
  border: 1px solid rgba(0, 125, 250, 0.8);
  background: rgba(0, 125, 250, 0.4);
}

.grid-wrapper {
  margin: 10px 10px 5px;
}

.grid-flex {
  display: flex;
  width: 136px;
  justify-content: space-between;
  padding: 1px;
  cursor: pointer;
}

.grid-flex-item {
  flex: 0 0 15px;
  height: 15px;
}

.grid-flex-info {
  margin: 10px;
  font-size: 12px;
}

.table-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.table-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 230px;
  border: 1px solid base.$colorGreySky;
  padding: 15px;
  border-radius: 2px;
  z-index: base.z-index(modal);
  background: white;
  justify-content: space-between;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.table-wrapper-button {
  width: 37px;
  height: 32px;
  border: 1px solid base.$colorWhite;
}

.table-wrapper-button:hover {
  box-shadow: 3px 3px 5px #bfbdbd;
}

.table-modal-header {
  font-size: 15px;
  display: flex;
}
.table-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.table-modal-header-label {
  width: 95px;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
  border-bottom: 2px solid #0a66b7;
}
.table-modal-link-section {
  display: flex;
  flex-direction: column;
}

.table-modal-btn-section {
  display: flex;
  justify-content: center;
}
.table-modal-btn {
  margin: 0 3px;
  width: 75px;
  height: 30px;
  border: 1px solid base.$colorGreySky;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.table-modal-btn:hover {
  box-shadow: 1px 1px 0px base.$colorFog;
}
.table-modal-btn:active {
  box-shadow: 1px 1px 0px base.$colorFog inset;
}
.table-modal-btn:disabled {
  background: #ece9e9;
}

.table-cell-style {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 6px;
  text-align: center;
}

.table-cell-style--th {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 6px;
  text-align: center;

  background-color: base.$colorFog;
}
