@use "../base";

.breadcrumbs {
  display: flex;
  gap: var(--space-050);
  margin: var(--space-100);
  margin-left: var(--space-600);
  color: var(--primary-color);

  a {
    color: var(--primary-color);
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  @include base.no-aside {
    margin-left: var(--space-200);
  }

  @include base.mobile {
    margin-left: var(--space-100);
    a,
    span {
      display: none;
    }
    a.mobile {
      display: inline;
    }
  }
}
