@use "../base";

.c-icon-filter {
  height: 9em;
  padding: 0.5rem 0.2rem;
  border-radius: 5px;
  margin: 5px;
  box-sizing: border-box;
  word-wrap: break-word;
  cursor: pointer;
  background-color: base.$colorWhite;
  transition: background-color 0.3s ease;
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  font-size: base.$fontSmall;
  color: base.$colorPineGreen;
  border: solid 1px base.$colorWhite;
}

@media (min-width: 620px) {
  .c-icon-filter:last-child {
    margin-right: 0;
  }
  .c-icon-filter {
    width: 154px;
    height: 150px;
  }
}

.c-icon-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -5px;
}

.c-icon-filter-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  min-width: 0;
}

.c-icon-filter svg {
  width: 100%;
  height: 80px;
  margin-bottom: base.$spacingSmall;
}

.c-icon-filter--focused {
  outline: 1px dotted Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.c-icon-filter--checked {
  background-color: base.$colorBrightGreen !important;
}

.c-icon-filter--checked .c-icon-filter__name::before {
  content: "✓ ";
}

.c-icon-filter__name {
  min-height: 25px;
}

.c-icon-filter:hover {
  background-color: base.$colorLuminousGreen;
}
