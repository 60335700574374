.document-admin {
  &-documents {
    padding: var(--space-400);
    max-width: var(--renblad-max-width);
    margin: 0 auto;

    .document-list-item {
      display: flex;
      gap: var(--space-050);
      align-items: center;
      a {
        color: var(--primary-color);
      }
    }
  }
  &-cards {
    padding: var(--space-400);
    background-color: var(--tertiary-color);
    max-width: var(--renblad-max-width);
    margin: 0 auto;
    margin-bottom: var(--space-400);

    .top-card {
      padding: var(--space-150);
      border: var(--dimension-border-default) solid var(--dark-pine-color);
      background-color: var(--white-color);
      position: relative;
      width: fit-content;

      &-wrapper {
        display: flex;
        gap: var(--space-150);
        align-items: flex-end;
      }

      button {
        display: flex;
        align-items: center;
        gap: var(--space-025);
      }

      &:after {
        content: "";
        position: absolute;
        top: -1px;
        left: var(--space-200);
        width: 90px;
        height: 12px;
        background-color: var(--secondary-color);
      }

      &:before {
        content: "";
        position: absolute;
        height: 3px;
        bottom: calc(var(--space-200) * -0.5);
        right: -3000px;
        left: 0;
        border: var(--dimension-border-default) solid var(--dark-pine-color);
        border-style: solid none;
      }
    }

    .sub-card {
      padding: var(--space-150);
      border: var(--dimension-border-default) solid var(--dark-pine-color);
      background-color: var(--tertiary-color);
      width: fit-content;
      height: max-content;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(var(--space-200) / 2);
        top: calc(var(--space-200) * -0.5);
        border: var(--dimension-border-default) solid var(--dark-pine-color);
      }

      button {
        display: flex;
        align-items: center;
        gap: var(--space-025);
      }

      .expander {
        position: absolute;
        bottom: 0;
        right: var(--space-025);
      }

      .document-list-wrapper {
        display: none;
      }
      .document-list {
        li {
          button {
            margin: 0px;
            padding-left: 0px;
          }
        }
      }

      &.expanded {
        .document-list-wrapper {
          display: block;
        }
        button svg {
          transform: rotate(180deg);
        }
      }
    }

    .doc-sub-card {
      padding: var(--space-150);
      border: 2px dotted var(--dark-pine-color);
      background-color: var(--tertiary-color);
      width: fit-content;
      height: max-content;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(var(--space-200) / 2);
        top: calc(var(--space-200) * -0.5);
        border: var(--dimension-border-default) solid var(--dark-pine-color);
      }

      button {
        display: flex;
        align-items: center;
        gap: var(--space-025);
      }

      .expander {
        position: absolute;
        bottom: 0;
        right: var(--space-025);
      }

      .document-list-wrapper {
        display: none;
      }

      .document-list {
        li {
          button {
            margin: 0px;
            padding-left: 0px;
          }
        }
      }

      &.expanded {
        .document-list-wrapper {
          display: block;
        }
        button svg {
          transform: rotate(180deg);
        }
      }

      .top-section {
        display: flex;
        flex-direction: column;
        gap: var(--space-200);
        margin: var(--space-300) 0;
        overflow: hidden;
      }

      .sub-section {
        display: flex;
        flex-wrap: wrap;
        gap: var(--space-150);
      }

      .create-card-button {
        height: fit-content;
        margin: 0px;
      }
    }

    .top-section {
      display: flex;
      flex-direction: column;
      gap: var(--space-200);
      margin: var(--space-300) 0;
      overflow: hidden;
    }

    .sub-section {
      display: flex;
      flex-wrap: wrap;
      gap: var(--space-150);
    }

    .create-card-button {
      height: fit-content;
      margin: 0px;
    }
  }
  .create-card-form,
  .edit-card-form,
  .create-document-form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    padding: var(--space-100);
    background: var(--white-color);
    gap: var(----space-050);

    button {
      margin: 0px;
      display: block;
    }

    .form-input {
      display: flex;
      flex-direction: column;

      label {
        font-weight: 400;
        font-size: var(--font-size);
      }
      input,
      textarea,
      select {
        padding: var(----space-050);
        font-size: var(--font-size);
        font-weight: 300;
        border-radius: var(--border-radius-default);
        border: var(--dimension-border-default) solid
          var(--input-color-border-default);
      }

      textarea {
        min-height: 200px;
        min-width: 100%;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: var(--space-025);
    }
  }
}
