@use "../base";

.c-editable-note {
  position: relative;
  transition: background-color 0.2s ease-out;

  &__menu {
    position: absolute;
    bottom: 0;
    right: base.$spacingLarge;
    display: none;
    // display: flex;
    background-color: white;
    border: 1px solid base.$color3000Secondary;
    box-sizing: border-box;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
  }

  &:hover {
    background-color: base.$colorFog;
    .c-editable-note__menu {
      display: flex;
    }
    & a.copyable-link {
      width: 30px;
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
