@use "../base";

.c-card-minor {
  padding: 0 base.$spacingLarge base.$spacing;
  box-sizing: border-box;
  height: 100%;
  transition: transform 0.15s ease-in-out;
  min-height: 15em;
  border: 1px solid var(--black-color);
  font-size: var(--body-font-size);
}

.c-card-minor--flex {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 13.9em;
}

.c-card-minor__top-bar--yellow {
  height: var(--space-xx-small);
  width: 100px;
  background-color: base.$colorSunny;
  margin-block-start: 0;
  margin-block-end: var(--space-xx-small);
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.c-card-minor__top-bar--green {
  height: var(--space-xx-small);
  width: 100px;
  background-color: base.$colorBrightGreen;
  margin-block-start: 0;
  margin-block-end: var(--space-xx-small);
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.c-card-minor__header {
  margin-top: base.$spacing;
  margin-bottom: base.$spacing;
  color: base.$colorPineGreen;
  word-break: keep-all;
}
@media screen and (max-width: 40em) {
  .c-card-minor__header {
    font-size: base.$fontLarge;
  }
}
.c-card-minor__header--bold {
  font-weight: 500;
}

.c-card-minor__date {
  margin: 0;
  font-weight: 400;
}
.c-card-minor__place {
  margin: 0;
  font-weight: 400;
}

.c-card-minor__list {
  list-style: disc;
  color: var(--black-color);
  font-size: var(--label-font-size);
}

.c-card-minor__arrow {
  color: var(--pine-green-color);
  position: absolute;
  right: 0;
  bottom: 0;
}

@media all and (-ms-high-contrast: none) {
  .c-card-minor {
    width: 330px;
    margin-bottom: base.$spacing;
  }
}
