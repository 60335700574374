@use "../base";

.c-card-employee {
  background-color: base.$colorPineGreen;
  margin-bottom: base.$spacingTiny;
}

.c-card-employee--padding-smallVersion {
  .c-card-employee__credentials {
    padding: base.$spacingSmall base.$spacing;
  }
}

.c-card-employee--light {
  background-color: base.$colorGreySky;
}

.c-card-employee__head {
  display: flex;
}
.c-card-employee__image {
  object-fit: cover;
}

.c-card-employee__credentials {
  padding-left: base.$spacing;
  h3 {
    line-height: 1.7;
    color: base.$colorWhite;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.c-card-employee__role {
  color: base.$colorWhite;
  font-weight: normal;
}

.c-card-employee--light .c-card-employee__credentials {
  h3 {
    color: base.$colorPineGreen;
  }
}

.c-card-employee--light .c-card-employee__role {
  color: base.$colorPineGreen;
}

.c-card-employee__email {
  width: 100%;
  color: base.$colorWhite;
  text-decoration: underline;
  line-height: 1.7;
}

.c-card-employee__email:hover {
  font-weight: 500;
  text-decoration: underline;
}

.c-card-employee--light .c-card-employee__email {
  color: base.$colorBlack;
}

.c-card-employee__phone {
  width: 100%;
  color: base.$colorWhite;
  text-decoration: underline;
  line-height: 1.7;
}

.c-card-employee__phone:hover {
  font-weight: 500;
  text-decoration: underline;
}
.c-card-employee--light .c-card-employee__phone {
  color: base.$colorBlack;
}

.c-card-employee__body {
  margin-top: base.$spacing;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (max-width: 59.99em) {
  .c-card-employee__credentials {
    h3 {
      font-size: base.$fontLarge;
    }
  }

  .c-card-employee__body {
    display: none;
  }
  .c-card-employee__credentials {
    padding: base.$spacing;
  }
}

@media (min-width: 60em) {
  .c-card-employee--padding {
    padding: base.$spacingLarge base.$spacingLarge base.$spacing base.$spacingLarge;
  }
}
