@use "../base";

.c-editorpage-content {
  max-width: var(--renblad-content-width);

  .c-expandable-content {
    display: flex;
    flex-direction: column;
    gap: var(--space-100);

    // Height of the richtext editor
    div[role="textbox"] {
      margin: 1em 0;
    }
  }

  h2 {
    margin-top: var(--space-small);
    margin-bottom: var(--space-small);
  }

  h3 {
    margin-top: var(--space-x-small);
  }

  section {
    margin-top: var(--space-small);
  }

  header.renblad-header {
    padding-bottom: 0;
  }

  button {
    margin-right: var(--space-small);
  }

  input {
    outline: 1px solid var(--black-color);
    border-radius: var(--border-radius-default);
    margin: var(--space-xx-small);
  }

  input[type="radio"] {
    outline: unset;
  }

  textarea {
    outline: 1px solid var(--black-color);
    border-radius: var(--border-radius-default);
    margin: var(--space-x-small);
    height: 150px;
    padding: var(--space-xx-small);
    margin-left: 0;
    width: 100%;
  }

  .email-wrapper {
    margin: var(--space-small) 0;
  }

  .radio-group {
    margin-top: var(--space-small);
    margin-bottom: var(--space-small);

    &__options {
      display: flex;
      gap: var(--space-small);

      input {
        margin-right: var(--space-small);
      }
    }
  }

  .checkbox {
    margin-top: var(--space-small);
  }

  &__version {
    p {
      margin-top: var(--space-xx-small);
    }
  }

  .c-publish-error-box {
    margin-top: var(--space-large);
  }

  .c-editorpage-content__publish__form {
    ul li {
      list-style: disc;
    }
  }

  span.error {
    display: flex;
    color: red;
    gap: var(--space-xx-small);
  }

  .c-editorpage-content__access {
    ul li {
      list-style: disc;
    }
  }

  // Sections
  &__title {
    button.c-button-simple {
      display: flex;
      align-items: center;
      margin-top: 0;
      padding: 0;

      svg {
        margin-right: var(--space-xx-small);
      }
    }

    input[type="text"] {
      width: 100%;
      padding: var(--space-small);
    }
  }

  &__access {
    li {
      list-style: disc;
    }

    button {
      margin-top: 0;
      padding: 0;
    }
  }

  &__deprecation {
    &__reason,
    &__date {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-small);
    }

    .datepicker__input {
      display: flex;
      gap: var(--space-small);
    }

    input[type="date"] {
      width: 100%;
      font-size: var(--body-font-size);
      font-weight: var(--font-weight);
      padding: var(--space-small);
    }

    button.c-button-secondary-green {
      margin-right: var(--space-small);
    }
  }

  &__publish__form {
    display: flex;
    flex-direction: column;
    gap: var(--space-100);
  }

  &__changelog {
    display: flex;
    flex-direction: column;
    gap: var(--space-100);

    .c-richText-editor__content-controls {
      padding-bottom: var(--space-small);
    }

    &--blockcontent {
      padding: var(--space-small);
      border: 1px solid var(--pine-green-color);
    }

    p#update-error {
      padding: base.$spacing;
      border-left: 5px solid red;
      background-color: pink;
    }

    .renblad-changelog-previous .c-portableText {
      li {
        list-style: disc;
      }
    }
  }
}
