@use "../../base";

/* Default Theme Styles */

// Left line before pseudo element.
// this is put on top of a border set on the top toc-list
.is-active-link::before {
  background-color: base.$colorBrightGreen;
  content: " ";
  display: inline-block;
  height: 1.1rem;
  left: 0;
  position: absolute;
  width: 2px;
}
