@use "../base";

.rdw-editor-wrapper {
  border: 2px solid base.$colorPineGreen;
  //min-height: 30em;
  margin-bottom: base.$spacing;
}
.DraftEditor-root {
  height: 25em;
}
.rdw-editor-flex {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.rdw-editor-flex-child {
  margin-right: base.$spacing;
}

.rdw-editor-flex-child-last {
}

.c-draft-button {
  svg {
    padding-left: 4px;
    vertical-align: middle;
  }
}

// overwrite toast-ui editor-contents with our own styling
.tui-editor-contents code {
  background-color: base.$colorFog !important;
}

.tui-editor-contents a {
  cursor: pointer !important;
}

.tui-editor-icon {
  width: 16px;
  height: 16px;
}

.c-toast-guide {
  display: flex;
  flex-direction: column;
  li {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.c-toast-guide-element-title {
  margin-bottom: 0.5rem;
}

.c-toast-guide-element {
  background-color: base.$colorFog;
  border: 1px solid #999;
  display: block;
  padding: 20px;
  width: 80%;
}
.c-toast-guide-element-image {
  display: block;
  width: 100%;
}

.c-toast-guide-element-text {
  margin-top: 1rem;
  display: block;
}

.c-draft-preview {
  p:first-child {
    margin-top: 0;
  }
  a {
    cursor: pointer;
  }

  code {
    font-family:
      Consolas,
      Courier,
      "Apple SD 산돌고딕 Neo",
      -apple-system,
      "Lucida Grande",
      "Apple SD Gothic Neo",
      "맑은 고딕",
      "Malgun Gothic",
      "Segoe UI",
      "돋움",
      dotum,
      sans-serif;
    border: 0;
  }

  :not(pre) > code {
    color: #c1798b;
    background-color: base.$colorFog;
    padding: 2px 3px;
    letter-spacing: -0.3px;
    border-radius: 2px;
  }

  pre > code {
    background-color: base.$colorFog;
    border: 1px solid #999;
    display: block;
    padding: 20px;
  }

  blockquote {
    margin: 14px 0;
    border-left: 4px solid #e5e5e5;
    padding: 0 16px;
    color: #999;
  }

  table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 12px 0 14px;
    color: #222;
    width: auto;
    border-collapse: collapse;
    box-sizing: border-box;

    th,
    td {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 14px 5px 12px;
      height: 32px;
    }

    th {
      background-color: #555;
      font-weight: 300;
      color: base.$colorWhite;
      padding-top: 6px;
    }
  }
}
