@use "../base";

.theme-renblad {
  @include base.no-aside {
    width: 100%;
  }

  @include base.mobile {
    width: 100%;
  }
}

.renblad-wrapper {
  background-color: var(--tertiary-color);
  padding-bottom: var(--space-large);
}

.renblad {
  font-family: var(--font-family-sans), sans-serif;
  line-height: var(--line-height);
}

.c-renblad {
  display: flex;
  background-color: var(--white-color);
  height: fit-content;
  max-width: var(--renblad-max-width);
  margin: 0 auto;

  @include base.no-aside {
    width: 100%;
  }

  @include base.mobile {
    width: 100%;
  }

  &__error {
    padding-top: var(--space-x-large);
    flex-direction: column;
    align-items: center;

    a {
      margin: var(--space-x-small);
    }

    p {
      max-width: var(--renblad-content-width);
      padding-inline: var(--space-small);
    }
  }

  .c-portableText {
    max-width: var(--renblad-content-width);

    @include base.mobile {
      max-width: base.$renblad-small-mobile;
    }
  }

  .c-info-yellow {
    align-items: center;
    display: flex;
    flex-direction: row;

    font-size: var(--font-size);

    padding: var(--space-small);
    margin-top: var(--space-medium);

    background: var(--vanilla-color);
    border-top: 5px solid;
    border-color: var(--sunny-color);

    ul {
      list-style: revert;
    }

    &__margin {
      margin-right: var(--renblad-content-margin-right);
    }

    h2 {
      color: var(--black-color);
      font-size: var(--label-font-size);
      font-weight: var(--font-bolder-weight);
      text-transform: uppercase;
    }

    svg {
      flex-shrink: 0;
      align-self: flex-start;
      padding-right: var(--space-x-small);
      width: var(--space-large);
      height: var(--space-large);
    }

    a {
      font-size: var(--body-link-font-size);
      &:link,
      &:visited {
        color: base.$colorPineGreen;
        text-decoration: underline;
      }

      &:hover {
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }

  ul li::marker {
    color: var(--primary-color);
  }

  @media print {
    flex-direction: column;
  }

  &__aside {
    flex-basis: var(--renblad-aside-width);
    flex-grow: 0;
    flex-shrink: 0;
    min-width: var(--renblad-aside-min-width);
    font-size: var(--toc-font-size);
    margin: 0;
    padding: var(--space-medium) var(--renblad-aside-horizontal-padding);
    word-wrap: break-word;
    background-color: base.$colorGreySky;

    @media screen and (max-width: base.$renblad-no-aside) {
      display: none;
    }

    @media print {
      display: block !important;
      flex-basis: 100%;
      max-width: 100%;
      background-color: transparent;

      select,
      button {
        display: none;
      }
    }
  }
  &__main {
    flex-basis: 56rem;
    flex-grow: 1;
    flex-shrink: 1;
    padding-bottom: var(--space-large);
    padding-left: var(--renblad-content-padding-left);
    font-size: var(--body-font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);

    @include base.mobile {
      padding-left: var(--space-200);
      padding-right: var(--space-200);
    }

    .renblad-header {
      padding-top: base.$spacingLarge;
      padding-bottom: base.$spacingLarge;
      color: var(--font-color);
    }

    // Typography
    & {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: var(--heading-line-height);
        font-weight: var(--font-bold-weight);
      }
      h1 {
        font-size: var(--heading-largest-font-size);
      }
      h2 {
        color: var(--primary-color);
        font-size: var(--heading-large-font-size);
      }
      h3 {
        font-size: var(--heading-medium-font-size);
      }
      h4,
      h5,
      h6 {
        font-size: var(--heading-small-font-size);
      }
    }

    & {
      .bold-label {
        font-size: var(--label-font-size);
        font-weight: var(--font-bolder-weight);
        color: var(--black-color);
      }
    }

    & {
      .c-renblad-content a {
        font-size: var(--body-link-font-size);
        color: var(--pine-green-color);
        font-weight: 400;
        text-wrap: avoid;
      }
    }

    .version-warning {
      background-color: var(--sunny-color);
      padding: var(--space-x-small) var(--space-small);
      margin-bottom: var(--space-small);
      margin-left: calc(-1 * var(--renblad-content-padding-left));

      display: flex;
      flex-direction: row;
      gap: var(--space-xx-small);

      position: sticky;
      top: var(--renblad-menu-height);
      z-index: 1;

      @include base.mobile {
        top: calc(
          var(--renblad-menu-height) + var(--renblad-mobile-toc-height)
        );
      }

      svg {
        width: var(--space-small);
        height: var(--space-small);
      }

      a {
        display: flex;
        align-items: center;
        gap: var(--space-xx-small);
      }

      &-close {
        margin-left: auto;
      }
    }

    @media print {
      max-width: 100%;
    }
  }
}
.c-portableText--renblad > .c-renblad-content {
  scroll-margin-top: 5rem;

  @include base.mobile {
    scroll-margin-top: 7rem;
  }
}

.c-renblad-content {
  .bold-label {
    font-size: var(--label-font-size);
    font-weight: var(--font-bolder-weight);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  p:empty {
    display: none;
  }

  @media print {
    max-width: 100%;
  }
}

.c-arbeidsversjon-validation-errors {
  background-color: pink;
  padding: 1.1rem;
  border-left: 5px solid red;
  width: var(--renblad-content-width);
}

.c-page-break {
  opacity: 0;
  line-height: 0;
  page-break-after: always;
}

.next {
  .c-page-break {
    opacity: 1;
    font-size: inherit;
    display: flex;
    align-items: center;
    text-align: center;

    &::before,
    &::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid #000;
    }

    &:not(:empty)::before {
      margin-right: 0.25em;
    }

    &:not(:empty)::after {
      margin-left: 0.25em;
    }
  }

  .c-renblad-content {
    p:empty {
      display: block;
    }
  }
}

.c-renblad-content-fullwidth {
  max-width: 100%;
}

.c-version-selector {
  display: flex;
  gap: var(--space-x-small);

  label {
    font-size: var(--label-font-size);
  }
  select {
    flex: 100% 1 1;
    padding: 4px;

    background: #ffffff;
    border: 0.5px solid #1e1f21;
    border-radius: 2px;
    width: 100%;
  }
}

.c-portableText--renblad,
.c-renblad-purpose {
  a.c-button,
  button.c-button {
    border: var(--primary-color);
    background-color: var(--primary-color);
    color: var(--white-color);
    font-weight: 400;

    svg {
      height: var(--space-small);
      width: var(--space-small);
      margin-left: var(--space-xx-small);
    }
  }

  a.c-button:hover,
  button.c-button:hover {
    background-color: var(--secondary-color);
  }
}

.c-renblad-purpose {
  background-color: var(--tertiary-color);
  padding: var(--space-medium);
  margin-right: var(--renblad-content-margin-right);

  .c-renblad-purpose__buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  h2 {
    text-transform: uppercase;
  }

  @media print {
    margin: 1rem 0;
    padding: 1rem;

    a {
      display: none;
    }
  }
}

.editor-tools {
  padding-block: var(--space-small);
  margin-right: var(--renblad-content-margin-right);
  li {
    list-style-type: disc;
    .error-link {
      color: red;
      font-weight: bold;
    }
  }

  .link-checker {
    background-color: var(--grey-sky-color);
    padding-inline: var(--space-small);
    padding-block: var(--space-medium);
  }
}

.notification {
  display: inline-block;
  background: base.$colorGreySky;
  margin-left: base.$spacingSmall;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
}

.htmlrenblad-results {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  gap: base.$spacing;

  @media screen and (max-width: 59.99em) {
    grid-template-columns: 100%;
  }
}

.htmlrenblad-filterbar {
  display: flex;
  flex-direction: column;
}

.htmlrenblad-filterbar-categories {
  display: flex;
  justify-content: space-between;
}

.htmlrenblad-filterbar-filters {
  display: flex;
  justify-content: space-between;
}

.htmlrenblad-card-container {
  display: flex;
  flex-direction: column;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  padding: base.$spacingSmall;
}

.htmlrenblad-card-numberline {
  padding: base.$spacingSmall;
  display: flex;
  justify-content: space-between;
}
.htmlrenblad-card-number {
  padding: 0.6rem 0.45rem 0.5rem;
  background-color: var(--primary-color);
  color: base.$colorWhite;
  font-size: base.$fontSmall;
}

.htmlrenblad-card-favorite {
  align-self: center;
}

.htmlrenblad-card-title {
  padding: base.$spacingSmall;
  text-decoration: underline;
  color: var(--primary-color);
}
.htmlrenblad-card-purpose {
  padding: base.$spacing;
  margin: base.$spacingSmall;
  background-color: base.$colorWhite;
  color: base.$colorBlack;
  font-family: var(--font-family-sans), sans-serif;

  .htmlrenblad-card-purpose--title {
    font-weight: bold;
    font-size: base.$fontTiny;
  }

  .htmlrenblad-card-purpose--content {
    font-size: base.$fontSmall;
  }
}

.link-with-arrow {
  color: var(--pine-green-color);

  svg {
    display: inline-block;
    height: 1rem;
    width: 1rem;
  }
}

.link-anchor {
  scroll-margin-top: 5rem;
}
