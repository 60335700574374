@use "../base";
@use "sass:math";

.c-large-card {
  background-color: base.$colorPistacchio;
  padding: 1.8rem base.$spacing;
  display: flex;
  justify-content: space-between;
  margin: 0;
  color: base.$colorBlack;
  font-weight: 500;
  transition: transform 0.15s ease-in-out;
}
.c-large-card p {
  font-weight: 400;
}

.c-large-card:hover {
  transform: translate(math.div(base.$spacing, 4), math.div(base.$spacingNegative, 4));
}

.c-large-card__title {
  font-weight: 500;
}
