@use "../../base";

.c-benefits {
  padding: base.$spacing;
  h2 {
    font-size: base.$fontLarger;
    color: base.$colorDarkPine;
  }

  ul {
    margin-left: base.$spacingSmall;
  }

  ul li {
    list-style-image: url("/asset/icons/green-checkmark.svg");
    line-height: 1.5;
  }
}

.c-benefits__green {
  background-color: base.$colorPistacchio;
}

.c-benefits__yellow {
  background-color: base.$colorVanilla;
}
