@use "../base";

.c-selector {
  margin-top: base.$spacing;
  width: 100%;
}

.c-selector__flex {
  display: flex;
}

.c-selector__item {
  border: none;
  background-color: base.$colorFog;
  width: 15%;
  padding: base.$spacingSmall base.$spacing;
  font-size: base.$fontSmall;
  margin-right: base.$spacing;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background: base.$colorVanilla;
  }
}
@media (max-width: 59.99em) {
  .c-selector__item {
    display: none;
  }
}

@media (min-width: 60em) {
  .c-selector--hide-component {
    display: none;
  }
}
