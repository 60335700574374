@use "../base";

%button-disabled {
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(60%);
  cursor: not-allowed;
}

@mixin button-default-mixin(
  $bg,
  $text,
  $border: var(--dimension-border-default) solid
    var(--button-color-border-secondary-default),
  $hover-bg: var(--button-color-background-secondary-hover)
) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
  border: $border;
  border-radius: var(--border-radius-default);
  background: $bg;
  color: $text;
  font-family: var(--font-family-sans);
  font-size: var(--button-font-size);
  font-weight: var(--font-bold-weight);
  line-height: 150%;
  letter-spacing: 0.0225rem;

  .outer-frame {
    display: flex;
    height: var(--space-300);
    padding: var(--space-xx-small) var(--space-x-small);
    justify-content: center;
    align-items: center;

    .inner-label-frame {
      display: flex;
      height: var(--space-300);
      padding: var(--space-xx-small) var(--space-x-small);
      justify-content: center;
      align-items: center;
    }
  }

  &:hover {
    background: $hover-bg;
  }
}

button[disabled],
button.disabled,
a[disabled],
a.disabled {
  @extend %button-disabled;
}

.c-button,
c-button-primary-louminous {
  display: inline-block;
  position: relative;
  margin-top: base.$spacing;
  padding: base.$spacingSmall base.$spacing;

  background-color: base.$colorLuminousGreen;
  border: 3px solid base.$colorLuminousGreen;
  border-radius: 5px;

  text-align: center;
  text-decoration: none;

  font: inherit;
  font-size: var(--button-font-size);
  color: base.$colorBlack;

  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;

  svg {
    vertical-align: text-top;
  }

  &:hover {
    color: base.$colorBlack;
    background-color: base.$colorBrightGreen;
    border-color: base.$colorBrightGreen;
  }
}

.c-button-secondary-louminous {
  background-color: base.$colorWhite;
  color: base.$colorDarkPine;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: base.$font;
  font: inherit;
  margin-top: base.$spacing;
  padding: base.$spacingSmall base.$spacing;
  border-radius: 5px;
  border: 3px solid base.$colorPineGreen;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: base.$colorBrightGreen;
  }
}

a.c-button-htmlrenblad-beta:after {
  content: "Prøv";
  font-size: 0.8em;
  background-color: base.$colorMagenta;
  border: 1px solid base.$colorMagenta;
  border-radius: 3px;
  padding: 3px;
  color: base.$colorWhite;
  position: absolute;
  top: -15px;
  right: base.$spacingTiny;
}

.c-button-primary-default {
  @include button-default-mixin(
    none,
    var(--button-color-background-primary-default)
  );
}

.c-button-primary-green {
  @include button-default-mixin(
    var(--button-color-background-primary-default),
    var(--button-color-text-primary-default),
    var(--dimension-border-default) solid
      var(--button-color-background-primary-default),
    var(--button-color-background-primary-hover)
  );
}

.c-button-secondary-green {
  @include button-default-mixin(
    none,
    // No background color
    var(--color-brand-text-primary),
    var(--dimension-border-default) solid var(--color-brand-text-primary),
    var(--button-color-background-secondary-hover)
  );

  &:hover {
    color: var(--color-brand-text-primary);
    outline: 1px solid var(--color-brand-text-primary);
  }
}

.c-button-primary-magenta {
  @include button-default-mixin(
    var(--plum-color),
    var(--button-color-text-cta-default, #ffecee),
    var(--dimension-border-default) solid var(--magenta-color),
    var(--magenta-color)
  );
}

.c-button-secondary-magenta {
  @include button-default-mixin(
    none,
    var(--plum-color),
    var(--dimension-border-default) solid var(--plum-color),
    none
  );

  &:hover {
    color: var(--plum-color);
    outline: 1px solid var(--magenta-color);
  }
}

.c-button-secondary-default-small {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-default);
  border: var(--dimension-border-default) solid
    var(--button-color-border-secondary-default);
  background: var(--button-color-background-secondary-default);
  padding: 4px 14px;
  color: var(--button-color-text-secondary-default);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  line-height: 150%;
  letter-spacing: 0.32px;

  &:hover {
    background: var(--button-color-background-secondary-hover);
    border: var(--dimension-border-default) solid
      var(--button-color-border-secondary-hover);
    color: var(--button-color-text-secondary-hover);
  }
}

.c-button-themed {
  background-color: var(--primary-color);
  color: var(--tertiary-color);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: base.$font;
  font: inherit;
  margin-top: base.$spacing;
  padding: base.$spacingSmall base.$spacing;
  border: 2px solid var(--tertiary-color);

  svg {
    fill: var(--tertiary-color);

    &:hover {
      fill: var(--tertiary-shade);
    }
  }

  &:hover {
    color: var(--tertiary-shade);
    background-color: var(--secondary-color);
  }
}

.c-button-simple {
  text-decoration: underline;
  margin-top: base.$spacing;
  padding: base.$spacingSmall;
  color: base.$colorPineGreen;
  font-size: base.$font;
}

.c-button-warning-red-danger {
  background-color: var(--warning-red-color);
  color: var(--white-color);
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: base.$font;
  font: inherit;
  margin-top: base.$spacing;
  padding: base.$spacingSmall base.$spacing;
  border-bottom: 0px;
  transition: background-color 0.3s ease;
  border-radius: 5px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.c-button__icon {
  margin-left: base.$spacingSmall;
}

.c-button--underline {
  text-decoration: underline;
}

.c-rich-navbar-button {
  color: base.$colorWhite;
  font-size: base.$fontTiny;
  display: flex;
  gap: var(--space-xxx-small);
  align-items: center;
  border-radius: 5px;
  height: 100%;
  &__icon {
    vertical-align: text-bottom;
    width: 20px;
    height: 20px;
  }
  &__text {
    text-decoration: underline;
    font-size: base.$fontTiny;
    font-weight: 400;
  }
  &--active {
    background-color: base.$colorGreySky;
    color: base.$colorBlack;
  }
  &--disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  &:focus {
    outline: 2px solid base.$colorSky;
  }
}
