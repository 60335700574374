@use "../base";

.c-masthead .top-nav {
  display: flex;
  justify-content: space-between;
  padding: base.$spacingSmall base.$spacing;
  background-color: #fff;
}

.c-masthead-header {
  background-color: base.$colorSunny;
  color: base.$colorPineGreen;
  padding: base.$spacing;

  &__title {
    line-height: 1.4;
    font-weight: 500;
  }
}
