@use "../base";

.legacy-renblad-search .c-header__inner {
  margin-inline: unset;
}

.c-renblad-listing--heading {
  border-bottom: 1px solid black;
  padding-bottom: base.$spacingSmall;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-renblad-listing--single--company-comment {
  .c-accordion__label button {
    font-weight: 300;
  }
}

.c-renblad-listing--version-comment {
  white-space: break-spaces;
}

.c-renblad-listing--changes-comment {
  white-space: break-spaces;
  font-style: italic;
  color: #a0a0a0;

  &.c-renblad-listing--changes-comment-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.c-renblad-listing__meta {
  display: flex;
  margin-top: base.$spacingLarge;
  font-size: base.$fontTiny;
  padding: 0 base.$spacing;
  flex-direction: row;
}

.c-renblad-listing--single--header {
  display: flex;
  flex-direction: row;
  align-items: top;
  flex-wrap: wrap;
}

.c-renblad-listing__meta p,
.c-renblad-listing__item,
.c-renblad-listing__title {
  margin: 0;
  font-weight: 400;
  line-height: 1.7;
  font-size: var(--font-size);
}

.c-renblad-listing__title {
  text-align: left;
  width: 100%;
}

.c-renblad-listing--single {
  background-color: base.$colorWhite;
  margin-bottom: base.$spacingSmall;
  margin-top: base.$spacingSmall;
  padding: base.$spacing;

  &.c-renblad-listing--single-obsolete {
    opacity: 0.7;
  }

  &.c-renblad-listing--single-open {
    margin-bottom: 2rem;
  }
}

.c-renblad-listing__tags {
  display: inline-flex;
  vertical-align: bottom;
  line-height: 1.7em;
  margin-left: 0.5rem;

  > span {
    line-height: 1.45em;
  }
}

.c-renblad-listing__item {
  .favorite-button svg {
    color: var(--dark-pine-color);
    width: 1.3rem;
    height: 1.3rem;
  }
}

.c-renblad-listing__item,
.c-renblad-listing__meta-item {
  margin: 0;
  font-weight: 400;
  width: 31.66%;
}

.c-renblad-listing__arrow,
.c-renblad-listing__meta-arrow {
  display: inline-block;
  width: 5%;
  text-align: right;
}

.c-renblad-listing__arrow button {
  color: base.$colorBrightGreen;
}

.c-renblad-listing__content {
  order: 2;
  width: 100%;
}

.c-renblad-listing__content p {
  font-size: var(--body-font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  margin-block: 1em;
}
@media screen and (min-width: 40em) {
  .c-renblad-listing--single--header,
  .c-renblad-listing__meta {
    flex-wrap: nowrap;
  }
  .c-renblad-listing__content,
  .c-renblad-listing__meta-content {
    order: inherit;
    //width: 63%;
    width: 71%;
  }
  .c-renblad-listing__item,
  .c-renblad-listing__meta-item {
    width: 8%;
  }

  .c-renblad-changes-listing {
    .c-renblad-listing__content,
    .c-renblad-listing__meta-content {
      width: 63%;
    }
    .c-renblad-listing__item,
    .c-renblad-listing__meta-item {
      width: 7%;
    }
    .c-renblad-listing__item:nth-child(3),
    .c-renblad-listing__meta-item:nth-child(3) {
      width: 13%;
    }
  }

  .c-renblad-listing__item--center,
  .c-renblad-listing__meta-item--center {
    text-align: center;
  }
  .c-renblad-listing__item-readonly {
    opacity: 30%;
  }
  .c-renblad-listing__item--svg {
    line-height: 1.4em;
  }
  .c-renblad-listing__arrow,
  .c-renblad-listing__meta-arrow {
    width: 5%;
  }

  .c-renblad-listing__download-icon {
    a svg {
      margin: 0 var(--space-xxx-small);
      color: var(--primary-color);
      height: var(--heading-medium-font-size);
      width: var(--heading-medium-font-size);
    }
  }
}

@media screen and (min-width: 70em) {
  .c-renblad-listing__content,
  .c-renblad-listing__meta-content {
    order: inherit;
    width: 79%;
  }
  .c-renblad-listing__item,
  .c-renblad-listing__meta-item {
    width: 6%;
  }
  .c-renblad-listing__arrow,
  .c-renblad-listing__meta-arrow {
    width: 3%;
  }

  .c-renblad-changes-listing {
    .c-renblad-listing__content,
    .c-renblad-listing__meta-content {
      width: 65%;
    }
    .c-renblad-listing__item,
    .c-renblad-listing__meta-item {
      width: 5%;
    }
    .c-renblad-listing__item:nth-child(3),
    .c-renblad-listing__meta-item:nth-child(3) {
      width: 10%;
    }
  }
}

.c-renblad-listing--single--header--item {
  display: flex;
  flex-direction: row;
  p {
    margin-right: base.$spacingSmall;
  }
}

.c-renblad-listing--single--expanded {
  border-top: 2px solid base.$colorFog;
  margin-top: base.$spacing;
}

.c-renblad-listing--single--subtitle {
  font-weight: 500;
  font-size: 1em;
  margin-top: 2.2rem;
  font-size: base.$font;
}

.c-renblad-listing--single--subtitle svg {
  vertical-align: text-top;
}

.c-renblad-select--meta {
  display: flex;
  flex-direction: row;

  span {
    padding-top: 5px;
  }
}

.c-renblad-select--config {
  display: flex;
  justify-content: space-between;
}

.c-renblad-select--wrapper {
  display: inline-flex;
  padding: 0 5px;

  @media (max-width: 59.99em) {
    margin-bottom: base.$spacingTiny;
  }

  &:last-of-type {
    justify-content: flex-end;
  }
}

.c-renblad-select--label {
  margin-top: 3px;
}

.c-renblad-select--wrapper:last-of-type .c-renblad-select--label {
  text-align: right;
}

.c-renblad-select {
  padding: base.$spacingTiny base.$spacingSmall;
  margin-left: base.$spacingSmall;
}

@media (max-width: 59.99em) {
  .c-renblad-listing--heading {
    flex-direction: column-reverse;
  }

  .c-renblad-select--wrapper {
    width: auto;
  }

  .c-renblad-select--label,
  .c-renblad-select--meta span {
    width: 7rem;
    display: inline-block;
  }

  .c-renblad-select {
    width: 10rem;
  }
}

@media (max-width: 599.99px) {
  .c-renblad-select--config {
    display: block;
  }

  .c-renblad-select--wrapper {
    display: block;
  }

  .c-renblad-select--wrapper:last-of-type .c-renblad-select--label {
    text-align: left;
  }

  .c-renblad-select {
    width: 12em;
  }
}

.c-renblad-listing--single--linklist {
  list-style: initial;
  color: base.$colorDarkClouds; // Used for disabled "links" (not wrapped with <a>)

  a {
    color: base.$colorPineGreen;
  }

  a:link,
  a:visited {
    color: base.$colorPineGreen;
    text-decoration: none;
  }
  a:hover {
    font-weight: 500;
    text-decoration: underline;
  }
}

.c-renblad-listing__small-button {
  padding: base.$spacingTiny base.$spacingSmall;
  margin: 0 base.$spacingSmall;
  border: 1px solid base.$colorDarkClouds;
  background-color: base.$colorWhite;
  border-radius: 2px;
  font-weight: normal;
  color: base.$colorPineGreen;
  a:link,
  a:visited {
    color: base.$colorPineGreen;
    text-decoration: none;
  }
  a:hover {
    color: base.$colorLuminousGreen;
  }
}

.c-renblad-listing__small-button:hover {
  background-color: base.$colorPistacchio;
}

.c-renblad-listing__small-button svg {
  vertical-align: middle;
}

.c-renblad-listing__table-wrapper {
  overflow-x: auto;
}

.c-renblad-listing__table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  font-weight: 300;
}

.c-renblad-listing__table th {
  font-size: base.$fontTiny;
  padding-top: base.$spacingSmall;
  padding-bottom: base.$spacingSmall;
  padding-right: base.$spacing;
  vertical-align: top;
}

.c-renblad-listing__table td {
  border-top: 1px solid base.$colorDarkClouds;
  border-bottom: 1px solid base.$colorDarkClouds;
  padding-top: base.$spacingSmall;
  padding-bottom: base.$spacingSmall;
  padding-right: base.$spacing;
  vertical-align: top;
}

.c-renblad-listing--comment-title {
  font-size: 1em;
  font-weight: 300;
}

.c-renblad-listing--comment-preview {
  padding-left: 20px;
  border-left: solid 10px #e0e0e0;
}
