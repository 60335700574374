@use "../base";

.c-preparedness {
  display: flex;
  height: fit-content;

  &__aside {
    flex-basis: 25%;
    max-width: 25vw;
    word-wrap: break-word;
    background-color: base.$colorGreySky;
    padding: 0 base.$spacing;

    .u-sticky--top-left {
      top: 138px;
    }
  }

  &__main {
    flex-basis: 100%;
    padding: 0 base.$spacing;
    max-width: 75vw;

    @media screen and (max-width: 59.99rem) {
      /* set to to 90vw because 100vw breakes the layout */
      max-width: 90vw;
    }
  }
}
