@use "../base";

.c-richText-link {
  display: inline;
  position: relative;
}

.c-richText-link__form-wrapper {
  position: absolute;
  top: base.$spacingLarge;
  z-index: base.z-index(content);

  & > * {
    z-index: base.z-index(content-top);
  }
}

.c-richText-link__form {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: base.$spacingSmall;
  gap: base.$spacingSmall;
  min-width: 300px;

  button,
  p {
    margin: 0;
  }

  &-url,
  &-url-input {
    flex: 1 1 100%;
  }
}

.c-richText-link__edit-table-title {
  float: right;
}
