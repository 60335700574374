@use "../../base";

.enrollment-listing {
  grid-auto-rows: 1fr;
}

.card-elearning {
  color: var(--black-color);
  background-color: var(--white-color);
  box-shadow: 0 0 0 1px var(--black-color);

  overflow: hidden;

  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  svg {
    color: var(--dark-pine-color);
    height: var(--heading-largest-font-size);
    width: var(--heading-largest-font-size);
  }

  p,
  h3,
  div {
    padding-inline: var(--space-x-small);
    padding-block: var(--space-xx-small);
  }

  .svg-and-span {
    padding-left: var(--space-xxx-small);
  }

  .time-and-price {
    width: auto;
    display: flex;
    gap: var(--space-xx-small);
    place-items: center;

    svg {
      height: var(--space-medium);
      width: var(--space-medium);
    }

    .minutes {
      flex-grow: 1;
    }

    .free {
      padding: var(--space-xxx-small) var(--space-x-small);
      background-color: var(--sunny-color);
      border-radius: var(--space-x-small);
    }

    .price {
      font-weight: 400;
    }
  }

  .course-progress {
    hr {
      border-top: 1px solid var(--fog-color);
      margin-bottom: var(--space-small);
    }
    .course-progress-bar {
      background-color: var(--fog-color);
      padding: 0;
      border-radius: var(--space-small);
      .course-progress-indicator {
        background-color: var(--dark-pine-color);
        border-radius: var(--space-small);
      }
    }

    .progress-rate-text {
      text-align: right;
    }
  }
}

.card-elearning:hover {
  background-color: var(--grey-sky-color);

  h3 {
    text-decoration: underline;
  }
}

.card-elearning:focus {
  background-color: var(--grey-sky-color);
  outline: var(--Stroke-stroke, 1px) solid
    var(--Brand-color-Greyscale-Black, #1e1f21);

  h3 {
    text-decoration: underline;
  }
}

.elearning-page {
  .header-with-info {
    display: grid;
    align-items: start;
    grid-auto-flow: dense;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-medium);
    padding: var(--space-large) var(--space-medium);
    background-color: var(--vanilla-color);

    @include base.tablet {
      grid-template-columns: 1fr;
    }

    .course-lede {
      grid-column: 1;
      p {
        font-size: var(--heading-medium-font-size);
      }
    }
    .course-content {
      background-color: var(--white-color);
      padding: var(--space-medium);
      outline: var(--black-color) solid 1px;
      margin: var(--space-medium);
      grid-column: 2;
      grid-row: 1/3;
    }
    .course-buttons {
      grid-column: 1;
      display: flex;
      gap: var(--space-small);
    }
  }

  .video-and-contents,
  .buy-button-and-restore-purchase,
  .learning-goals-and-course-person,
  .grid-3-2 {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: var(--space-large);

    @media (max-width: base.$breakpoint-medium-tablet) {
      grid-template-columns: 1fr;
    }
  }

  h2 {
    font-size: var(--heading-large-font-size);
    font-weight: var(--font-bold-weight);
  }

  .price {
    font-weight: var(--font-bold-weight);
  }

  .buy-button-and-restore-purchase {
    padding-top: var(--space-small);
  }

  .buy-course-button {
    display: flex;
    justify-content: space-between;
    background-color: var(--plum-color);
    color: var(--white-color);
    padding: var(--space-small) var(--space-medium);
    text-align: left;
    width: 100%;
    height: max-content;
    border-radius: 0;
    font-size: var(--heading-medium-font-size);
    &:hover {
      text-decoration: underline;
    }
  }

  .restore-purchase-button {
    margin-top: var(--space-xx-small);
    font-weight: 500;
    font-size: var(--font-size);
    padding: var(--space-x-small) var(--space-small);
    border: 3px solid var(--black-color);
    border-radius: 5px;
  }

  .buy-form {
    background-color: var(--white-color);
    padding: var(--space-medium);
    .payment-buttons {
      display: flex;
      gap: var(--space-small);
      margin-block: var(--space-small);

      .payment-button {
        font-size: var(--font-size);
        font-weight: var(--font-weight);
        padding: var(--space-xx-small) var(--space-x-small);
        border: 1px solid var(--black-color);
        border-radius: 8px;
      }

      .payment-button__selected {
        background-color: var(--black-color);
        color: var(--white-color);
      }
    }

    .disclaimer {
      background-color: var(--vanilla-color);
      padding: var(--space-xx-small) var(--space-small);
    }

    .billing-info {
      padding-block: var(--space-small);
      label {
        span {
          padding-block: var(--space-xx-small);
          font-weight: var(--font-weight);
          display: block;
        }

        input {
          width: 100%;
          padding: var(--space-xx-small) var(--space-x-small);
          display: inline-block;
          border: 1px solid var(--black-color);
          box-sizing: border-box;
          font-size: var(--font-size);
          font-weight: var(--font-weight);
        }
      }
    }
    .confirm-purchase-button {
      background-color: var(--plum-color);
      font-size: var(--font-size);
      font-weight: var(--font-bold-weight);
      color: var(--white-color);
      padding: var(--space-x-small) var(--space-medium);
      border-radius: 5px;
    }
    .confirm-purchase-button:hover {
      background-color: var(--magenta-color);
    }
    .confirming-purchase-button {
      background-color: var(--grey-sky-color);
      font-size: var(--font-size);
      font-weight: var(--font-bold-weight);
      color: var(--black-color);
      padding: var(--space-x-small) var(--space-medium);
      border: 3px solid var(--black-color);
      border-radius: 5px;
    }
  }
}

.purchase-confirmation {
  &__item-and-billing {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-medium);
  }
}
