@use "../base";

.event-type__title {
  @media (min-width: 60rem) {
    padding-inline: var(--space-small);
    display: none;
  }
}

.c-relevant-course-link {
  color: var(--black-color);
  padding: var(--space-x-small) var(--space-small);
  display: flex;
  align-items: stretch;
  gap: var(--space-x-small);
  border-radius: base.$spacingSmall;
  transition: 0.15s;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

.c-relevant-course-link__live {
  display: none;
}

.c-highlighted-course {
  background-color: var(--louminous-green-color);
  .c-relevant-course-link__title {
    display: flex;
    align-items: center;
    position: relative;
    gap: var(--space-xx-small);
    .c-relevant-course-link__live {
      display: block;
      position: absolute;
      left: -4rem;
      svg {
        width: var(--space-small);
        height: var(--space-small);
        color: var(--dark-pine-color);
      }
    }
  }
}

.c-relevant-course-link:hover {
  background-color: var(--white-color);
}

.c-relevant-course-link--white:hover {
  background-color: base.$colorFog;
}

.c-relevant-course-link__title {
  margin: 0;
  width: 40%;
  font-weight: 500;
}

.c-relevant-course-link__date {
  margin: 0;
  width: 25%;
}

.c-relevant-course-link__location {
  margin: 0;
  width: 20%;
}

.c-relevant-course-link__status {
  margin: 0;
  width: 12%;
}

.c-relevant-course-link__arrow {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  width: 3%;

  svg path {
    stroke: base.$colorPineGreen;
  }
}

.c-relevant-course-link__underline {
  display: none;
  height: 1px;
  background-color: #b3b3b3;
}

@media (max-width: 59.99em) {
  .c-relevant-course-link {
    flex-direction: column;
    align-items: flex-start;
  }

  .c-relevant-course-link__live {
    display: none;
  }

  .c-relevant-course-link:hover {
    padding: base.$spacing;
    margin: 0 base.$spacingNegative;
    border-radius: 0px;
  }

  .c-relevant-course-link__title {
    width: 100%;
    font-weight: 500;
  }

  .c-relevant-course-link__date {
    width: 100%;
  }

  .c-relevant-course-link__location {
    margin: 0;
    width: 100%;
  }

  .c-relevant-course-link__status {
    width: 100%;
  }

  .c-relevant-course-link__arrow {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: base.$spacingNegative * 1.7;
  }

  .c-relevant-course-link__underline {
    display: block;
  }
}
