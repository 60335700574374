@use "../base";

.c-renblad-comment--heading {
  padding-bottom: base.$spacingSmall;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: 60em) {
    > div {
      padding-top: 2.2rem;
    }
  }

  @media (max-width: 59.99em) {
    flex-direction: column;
  }
}

.c-renblad-comment--meta {
  margin-bottom: 2.2rem;
}
.c-renblad-comment--content {
  margin-top: 2.2rem;
}
