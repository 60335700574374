@use "../base";

.theme-planbok {
  .c-shared-document-cards {
    flex-wrap: wrap;
  }
}

.c-shared-document {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: fit-content;
  max-width: var(--renblad-max-width);
  margin-bottom: var(--space-400);

  @include base.mobile {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  // Navigation cards on index page
  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-150);
    margin-left: var(--space-600);
    margin-right: var(--space-600);
    min-width: 200px;
    justify-content: center;

    p {
      margin-bottom: var(--space-200);
    }

    @include base.mobile {
      margin: var(--space-200);
    }

    a {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 300px;
      flex-direction: column;
      height: auto;
      min-height: 14rem;
      max-width: 400px;
      border: var(--dimension-border-default) solid
        var(--input-color-border-default);
      align-items: center;
      justify-content: center;
      padding: var(--space-200);
      overflow: hidden;
    }
  }

  &-title {
    margin-top: var(--space);
    margin-bottom: var(--space-small);
    font-size: var(--font-size-heading-1);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    align-self: stretch;
  }

  &-header {
    margin: var(--space-x-large) auto;
    max-width: var(--renblad-content-width);
    color: var(--primary-color);

    .title {
      color: var(--primary-color);
      font-weight: 500;
      margin-bottom: var(--space-medium);
    }

    .description {
      font-size: var(--lead-font-size);
      max-width: var(--renblad-content-width);
    }

    @include base.no-aside {
      margin-left: var(--space-600);
      margin-right: var(--space-600);
    }

    @include base.mobile {
      margin: var(--space-200) var(--space-200) 0 var(--space-200);
    }
  }

  &-content {
    max-width: var(--space-4000);
    margin-left: var(--space-600);
    line-height: 140%;

    @include base.no-aside {
      margin: var(--space-200);
    }

    @include base.mobile {
      margin: var(--space-100);
    }
  }

  &-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-inner {
    width: 100%;
  }

  &-sidemenu {
    flex-basis: var(--renblad-aside-width);
    flex-grow: 0;
    flex-shrink: 0;
    min-width: var(--renblad-aside-min-width);
    font-size: var(--font-size-sm);
    margin: 0;
    max-width: 350px;
    word-wrap: break-word;
    position: relative;
    margin-right: var(--dimension-border-default);
    border-right: var(--dimension-border-default) solid
      var(--button-color-border-secondary-default);

    @include base.no-aside {
      display: none;
    }

    a {
      display: block;
      padding: var(--space-050) var(--space-100);

      &:hover,
      &.active {
        color: var(--dark-pine-color);
      }
    }
  }
}
