@use "../base";

.c-card-link-two-illus {
  position: relative;
  height: 100%;
  min-height: 10em;
  padding: var(--space-small);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid var(--black-color);

  .c-card-link-two-illus__arrow-container {
    position: relative;
  }

  h3 {
    color: var(--color-brand-text-primary);
    flex-grow: 1;
  }
  p {
    color: var(--color-brand-text-primary);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .c-card-link-two-illus__image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.c-card-link-two-illus__arrow {
  position: absolute;
  right: var(--space-small);
  bottom: var(--space-small);
  color: var(--pine-green-color);
}

.c-card-link-two-illus__illu-item {
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  margin-top: base.$spacingNegative;
  margin-bottom: var(--space-x-small);

  &:last-child {
    justify-content: flex-end;
  }
}
