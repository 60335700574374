@use "../base";

.c-expandable {
  a {
    color: base.$colorPineGreen;
    text-decoration: none;
  }
  a:hover {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
  .c-expandable-title { // Targets the expandable titles at om-oss/faggrupper-i-ren
    color: var(--black-color);
    border: 1px solid var(--color-border-default);
    border-radius: 2px;
    padding: var(--space-xx-small) var(--space-x-small);
    margin-bottom: var(--space-xx-small);
    display: flex;
    justify-content: space-between;
    min-width: 100%;
  }
  // Styles the expandable title when it is expanded
  .c-expandable-title:has( + .c-expandable-content) {
    background-color: var(--color-layer-hover-1);
    margin-bottom: 0;
  }
  .c-expandable-title:hover, .c-expandable-title-text-element:hover {
    background-color: var(--color-layer-hover-1);
  }
}

.c-expandable-title {
  color: base.$colorPineGreen;
  width: fit-content;
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  line-height: 1.7;
  padding: 0;
  background-color: transparent;
}

.c-expandable-icon {
  transform: rotate(0deg);
}

.c-expandable-icon--expanded {
  transform: rotate(180deg);
}

.c-expandable-title-text-element {
  text-align: left;
}

.c-expandable-content {
  margin: 0 0 8px 0;
  border: 1px solid var(--color-border-default);
  border-top: 0;
  padding: var(--space-x-small);

  // Remove ul-margin for better control with gap
  ul {
    gap: var(--space-xxx-small);
    margin: 0;
  }

  // Remove top margin from first direct child and from all first-children paragraphs
  > :first-child, p:first-child {
    margin-top: 0;
  }

  // Targets the last child of the expandable content
  :last-child {
    margin-bottom: 0;
  }

  .c-expandable-leader {
    margin-bottom: 0;
  }

  .c-expandable-participants {
    margin-bottom: var(--space-small);
    margin-top: 0;

    // Display list of participants as if it were a <span>
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    p {
      margin: 0;
    }
    strong {
      margin-right: 0.2em;
    }
    // Display list of participants as a comma-separated list
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        line-height: 1.5em;
        display: inline;
        &::after {
          content: ', ';
        }
        &:last-child {
          &::after {
            content: '';
          }
        }
      }
    }
  }

  .c-expandable-members {
    ul {
      margin-top: 0;
      columns: 2;
      gap: var(--space-xxx-small);

      @include base.tablet {
        columns: 1;
      }
    }

    li {
      margin: 0;
    }
  }
}