@use "../base";

// Requires .c-renblad-content-fullwidth as well.
.c-renblad-image {
  position: relative;
  padding: base.$spacingSmall;
  border: 3px solid var(--primary-color);
  border-radius: 5px;

  figure {
    display: grid;
    grid-template-columns: 4fr 3fr;
    gap: base.$spacing;
    margin: 0;

    img {
      max-width: 100%;
      height: auto;
      &.svg-image {
        width: 100%;
        height: auto;
      }
    }
    figcaption {
      position: relative;
      padding-bottom: 50px;
    }

    @media print {
      display: flex;
      .fig-image {
        width: 68%;
        height: auto;
        .svg-image {
          max-width: 100%;
          max-height: 100%;
        }
      }
      figcaption {
        margin-left: 4%;
        width: 28%;
      }
    }
  }

  &__expanded figure {
    grid-template-columns: 1fr;

    @media print {
      grid-template-columns: unset;
      max-height: 100vh;
    }
  }

  &__expanded figure.low-aspect {
    grid-template-columns: 2fr 1fr;
  }

  // &__expanded figure.is-very-low-aspect {
  //   grid-template-columns: 1fr 1fr;
  // }

  &__controls {
    position: absolute;
    bottom: 0;
    margin-bottom: base.$spacingSmall;

    margin-top: base.$spacing;
    display: flex;
    justify-content: space-between;

    @media print {
      display: none;
    }

    .size {
      display: flex;
      align-items: center;
      padding: base.$spacingSmall;
      max-width: 130px;
      color: white;
      border-radius: base.$spacingTiny;
      font-size: var(--label-font-size);
    }

    .is-good {
      background-color: green;
    }

    .is-not-good {
      background-color: #97271e;
    }

    .svg-image {
      background-color: green;
    }

    button {
      margin-right: 1.1rem;
    }
  }

  button {
    border: 2px solid base.$colorBlack;
    border-radius: base.$spacingTiny;
    padding: base.$spacingSmall;
    display: flex;
    gap: base.$spacingSmall;
    align-items: center;

    &:hover {
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
