@use "../base";

.c-relevant-course-link-minor {
  padding: base.$spacingSmall base.$spacing;
  margin-bottom: base.$spacingTiny;
  display: flex;
  align-items: center;
  border-radius: base.$spacingSmall;
  background-color: base.$colorWhite;
}

.c-relevant-course-link-minor:hover {
  background-color: base.$colorDarkClouds;
}

.c-relevant-course-link-minor__title {
  margin: 0;
  width: 40%;
  font-weight: 500;
}

.c-relevant-course-link-minor__lede {
  margin: 0;
  width: 45%;
}

.c-relevant-course-link-minor__arrow {
  display: flex;
  justify-content: flex-end;
  width: 15%;
  color: base.$colorPineGreen;
}

@media (max-width: 59.99em) {
  .c-relevant-course-link-minor {
    flex-direction: column;
    align-items: flex-start;
    padding: base.$spacing;
    border-bottom: 3px solid base.$colorDarkClouds;
  }

  .c-relevant-course-link-minor__title {
    width: 100%;
    font-weight: 500;
  }

  .c-relevant-course-link-minor__lede {
    margin: 0;
    width: 100%;
  }

  .c-relevant-course-link-minor__arrow {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: base.$spacingNegative * 1.7;
  }
}
