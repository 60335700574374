@use "../base";

.c-grid-select {
  &_cell {
    padding: 0.75rem;
    margin: 0.1rem;
    background-color: base.$colorGreySky;
    cursor: pointer;

    &_hover {
      background-color: base.$colorFog;
    }
  }

  &_cell-display {
    text-align: center;
    margin: base.$spacingTiny;
  }
}
