@use "../base";

// Based on customNProgressBar.components.scss

.loading-spinner {
  display: block;
  padding: base.$spacing;

  &.loading-spinner--small {
    padding: base.$spacingTiny;

    .loading-spinner__icon {
      --size: 16px;
      --width: 3px;
    }
  }

  &.loading-spinner--large {
    padding: base.$spacingLarge;

    .loading-spinner__icon {
      --size: 36px;
      --width: 7px;
    }
  }
}

.loading-spinner__icon {
  --speed: 400ms;
  --color: var(--bright-green-color);
  --size: 24px;
  --width: 5px;
  width: var(--size);
  height: var(--size);
  box-sizing: border-box;
  border: solid var(--width) transparent;
  border-top-color: var(--color);
  border-left-color: var(--color);
  border-radius: 50%;
  -webkit-animation: loading-spinner var(--speed) linear infinite;
  animation: loading-spinner var(--speed) linear infinite;
}

@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
