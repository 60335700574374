@use "../base";

.c-profile-dropdown {
  a:link,
  a:visited {
    color: base.$colorPineGreen;
    text-decoration: none;
  }
  a:hover {
    color: base.$colorBrightGreen;
  }
}

.c-profile-button {
  display: flex;
  font-size: base.$fontTiny;
  align-items: center;
  color: base.$colorPineGreen;
  min-width: 200px;
  justify-content: space-around;
}

.c-profile-button:hover {
  color: base.$colorBrightGreen;
}
.c-profile-button__profileicon {
  margin-right: 10px;
}

.c-profile-button__arrowicon {
  margin-left: 10px;
}

.c-profile-open__wrapper {
  position: relative;
  z-index: base.z-index(above-top);
}

.c-profile-open {
  border: 1px solid base.$colorPineGreen;
  background-color: base.$colorWhite;
  padding: 0em 1em;
  margin-top: base.$spacingSmall;
  position: absolute;
}

.c-profile-open__list {
  margin: 0;
  padding: 0;
}
.c-profile-open__list li {
  margin-top: 1em;
  margin-bottom: 1em;
}
