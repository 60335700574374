@use "../../base";

.brukerguide-top .brukerguide-menu .modal 
  .toc-link {
    padding: 0;
    &:hover {
      border-bottom: none;
    }
}
.modal-nav {
  .toc-list-item {
    padding: 0.3em;
  }
  .open-btn {
    font-size: 1.5em;
    opacity: 0.1;
    transition: 1s;
  }

  .open-btn:hover {
    opacity: 1;
  }

  .is-closeable {
    overflow: hidden;
    transition: all 300ms ease-in-out;
  }
  .is-closed {
    max-height: 0;
  }

  .c-toc__content.js-toc > h3 {
    margin-bottom: 1em;
    border-bottom: 0.1px solid var(--primary-color);
  }

  .bold {
    font-weight: bolder;
  }

  ol.toc-list.is-closeable {
    padding-left: 1rem;
    border-left: solid 2px #eee;
  }

  div.c-toc__content.js-toc > ol.level-up:last-child {
    padding-bottom: 50em;
  }

  .pressed {
    opacity: 1;
  }

  /* The Modal (background) */
  .modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: var(--renblad-content-width);
  }

  .modal-btn {
    padding: 0.5em;
    margin-bottom: 2em;
    border-radius: 5px;
    color: var(--primary-color);
    width: 100%;
    transition: 0.2s;
  }
  .o-label-uppercase.modal-btn {
    margin-bottom: 2em;
  }
  .modal-btn:hover {
    background-color: var(--tertiary-color);
  }

  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .choose {
    color: var(--primary-color);
    padding: 0.5em;
    border-radius: 5px;
  }
  .choose:hover {
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
  }

  .chosen {
    color: var(--secondary-color);
    font-weight: bolder;
  }
}
