@use "../base";

.u-reset {
  color: base.$colorBlack;
  text-decoration: none;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
}
