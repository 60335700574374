@use "../base";

.c-program-day {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

.c-program-day p {
  margin-block: 1em;
}

.c-program-day--time {
  padding-right: base.$spacing;
  min-width: 6.5ch;
}

.c-program-day__button {
  border: none;
  color: base.$colorBlack;
  width: 100%;
  text-align: left;
  background-color: base.$colorVanilla;
  padding-left: base.$spacingSmall;
  padding-right: base.$spacingSmall;
}

.c-program-day__button-icon {
  background-color: inherit;
  padding-left: base.$spacingSmall;
  padding-right: base.$spacingSmall;
}
.c-program-day__button-icon--collapsed {
  transform: rotate(-90deg);
}

.c-program-day--wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--space-050);
}

.c-program-day--title {
  font-family: var(--font-family-sans), sans-serif;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}

.c-program-day--event__text {
  position: relative;
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -10px;
    width: 2px;
    height: 100%;
    background: base.$colorSunny;
  }
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: -14px;
    width: 11px;
    top: 28px;
    height: 11px;
    background: base.$colorSunny;
    border-radius: 50%;
  }
}

.c-program-day--event__text-title {
  font-family: var(--font-family-sans), sans-serif;
}
.c-program-day {
  margin-bottom: base.$spacing;
}

@media (max-width: 59.99em) {
  .c-program-day {
    background-color: base.$colorVanilla;
    padding: base.$spacingTiny base.$spacing;
  }
}

@media (min-width: 60em) {
  .c-program {
    background-color: base.$colorVanilla;
    padding: base.$spacing 0;
  }
}
