@use "../base";

@media (max-width: 59.99em) {
  .c-location__title {
    font-size: base.$fontLarge;
    margin: base.$spacingLarge 0 base.$spacing 0;
  }
}

@media (min-width: 60em) {
  .c-location {
    background-color: base.$colorVanilla;
    padding: base.$spacing 0;
  }

  .c-location__title {
    margin-bottom: base.$spacing;
  }
  .c-location__text {
    max-width: 60%;
    margin-bottom: base.$spacingLarge;
  }
}

.c-location__image {
  width: 2em;
  height: auto;
  transform: translate(-50%, -50%);
}

.c-location__map {
  width: 100%;
  height: 36em;
}
