@use "../base";

.c-quote {
  background-color: base.$colorWhite;
  display: flex;
  justify-content: space-between;
  font-size: base.$fontLarge;
  blockquote {
    margin-top: 0;
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
}

.c-quote__body {
  display: flex;
}

.c-quote__content {
  display: flex;
  align-items: center;
}

.c-quote__icon {
  margin-inline-start: 0;
  padding-left: base.$spacing;
  border-left: base.$spacingTiny solid base.$colorSunny;
  margin-left: base.$spacingNegative;
}

.c-quote__text {
  font-weight: 500;
}

.c-quote__image {
  width: 30%;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 60em) {
  .c-quote {
    padding: 0;
    blockquote {
      margin-inline-end: base.$spacing;
    }
  }
  .c-quote__body {
    width: 100%;
  }
  .c-quote__image {
    display: none;
    width: 0%;
  }
  .c-quote__icon {
    margin-left: 0;
  }
}
